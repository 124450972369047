import React from "react";
import { IonGrid } from "@ionic/react";
import "./BackgroundHoc.scss";
import { useSearchParams } from "react-router-dom";
import { STEPS } from "@constants/home.constants";

export interface props {
  children: React.ReactNode;
}

const BackgroundHoc = ({ children }: props) => {
  const [searchParams] = useSearchParams();
  const step: string | null = searchParams.get("step");

  return (
    <IonGrid className={`form_bg ${step == STEPS.ADDRESS && "footer_padding"} `}>
      {children}
    </IonGrid>
  );
};

export default BackgroundHoc;
