import React from "react";
import { IonGrid } from "@ionic/react";
import IconGridBox from "@components/IconGridBox/IconGridBox";
import "./TenantProfileForArea.scss";
import { TENANT_PROFILE_FOR_AREA, ICON_LIST, DIPLOMATS_KEY } from "@constants/report.constants";
import { FONT_COLOR, FONT_SIZE, FONT_TYPE, FONT_WEIGHT } from "@constants/enums/typography";
import DataCard from "@components/DataCard/DataCard";
import { useAppSelector } from "@hooks/redux";

const TenantProfileForArea = () => {
  const { report } = useAppSelector((state) => state.report);

  const iconList = ICON_LIST.map((icon) => {
    return {
      ...icon,
      show:
        icon.valuation_key === DIPLOMATS_KEY
          ? true
          : Boolean(report?.neighbourhoodData?.[icon.valuation_key])
    };
  });

  return (
    <DataCard
      title={{
        titleText: TENANT_PROFILE_FOR_AREA.title,
        titleFontType: [FONT_SIZE.text_6xl, FONT_WEIGHT.font_medium, FONT_TYPE.capitalize]
      }}
      footer={{
        hasDivider: true,
        footerText: TENANT_PROFILE_FOR_AREA.footer,
        footerFontType: [FONT_SIZE.text_xs, FONT_WEIGHT.font_medium, FONT_COLOR.grey600_text]
      }}
    >
      <IonGrid className="Tenant-profile-container">
        <IconGridBox iconList={iconList} />
      </IonGrid>
    </DataCard>
  );
};

export default TenantProfileForArea;
