import { STEPS } from "@constants/home.constants";
import { useAppSelector } from "@hooks";
import { IonGrid, IonRow, IonText } from "@ionic/react";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./EmailSent.scss";

const EmailSent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const firstName = useAppSelector((state) => state.valuation?.contactDetails?.firstName) || "";
  const formattedAddress =
    useAppSelector((state) => state.valuation?.address?.formatted_address) || "";
  const email = useAppSelector((state) => state.valuation?.contactDetails?.email) || "";

  const handleEmailCorrect = () => {
    searchParams.delete("step");
    searchParams.delete(STEPS.CONTACT_DETAILS);
    const previousSteps: Array<string> = JSON.parse(atob(searchParams.get("previousSteps") || ""));
    previousSteps.splice(previousSteps.length - 2);
    searchParams.set("previousSteps", btoa(JSON.stringify(previousSteps)));
    setSearchParams(searchParams);
    navigate({
      pathname: "/home",
      search: `?step=ContactDetails&${searchParams.toString()}`
    });
  };

  return (
    <IonGrid>
      <IonGrid className="contentContainer emailSentContainer">
        <IonRow>
          <IonText className="emailSentTitle">We've Emailed Your Report {firstName}</IonText>
        </IonRow>
        <IonRow className="mt_34">
          <IonText className="emailSentText">
            Your valuation for <span>{formattedAddress}</span> has been sent to <br />{" "}
            <span>{email}</span>
          </IonText>
        </IonRow>
        <IonRow>
          <IonText className="emailSentText mt_16">
            if your email address is incorrect, you can edit it{" "}
            <span className="correct-email" onClick={handleEmailCorrect}>
              here
            </span>
          </IonText>
        </IonRow>
      </IonGrid>
    </IonGrid>
  );
};

export default EmailSent;
