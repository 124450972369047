import React from "react";
import DataCard from "@components/DataCard/DataCard";
import {
  AGENT_PHONE_NUMBER,
  HBS_PAGE_LINKS,
  RENTAL_VALUE,
  SYMBOLS,
  unknown_text
} from "@constants/report.constants";
import { FONT_COLOR, FONT_SIZE, FONT_TYPE, FONT_WEIGHT } from "@constants/enums/typography";
import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import "./RentalValue.scss";
import Button from "@components/Button/Button";
import { useAppSelector } from "@hooks";
import Mixpanel from "@utils/mixpanel";
import { MixpanelEventNames } from "@utils/mixpanel/events";
import {
  JOURNEY_NAME,
  PAGE_VARIANT,
  REPORT_PAGE_BUTTONS,
  REPORT_PAGE_CARD_TITLES
} from "@utils/mixpanel/constants";

interface MixpanelRentalValueInterface {
  "Journey Name": string;
  "Button Pressed": string;
  "Page Variant": string;
  "Page Section": string;
}

const RentalValue = () => {
  const selector = useAppSelector((state) => state.report.report);
  const marketRentValuation = parseInt(selector?.valuationresult?.marketrentvaluation);

  const handleMixpanel = (button: string) => {
    const mixpanelData: MixpanelRentalValueInterface = {
      "Journey Name": JOURNEY_NAME.owner,
      "Button Pressed": button,
      "Page Variant": PAGE_VARIANT.A,
      "Page Section": REPORT_PAGE_CARD_TITLES.ESTIMATED_RENTAL_VALUE
    };
    Mixpanel(MixpanelEventNames.property_report_page_main_buttons, mixpanelData);
  };

  return (
    <DataCard
      title={{
        titleText: RENTAL_VALUE.title,
        titleFontType: [FONT_SIZE.text_6xl, FONT_WEIGHT.font_medium, FONT_TYPE.capitalize]
      }}
      footer={{
        hasDivider: true,
        footerText: RENTAL_VALUE.footer,
        footerFontType: [FONT_SIZE.text_xs, FONT_WEIGHT.font_medium, FONT_COLOR.grey600_text]
      }}
    >
      <IonGrid className="rental-value-container">
        <IonText
          className={`rental-value ${!marketRentValuation ? `grey600_text unknown-text` : ""}`}
        >
          {marketRentValuation && typeof marketRentValuation === "number"
            ? `${SYMBOLS.POUND}${marketRentValuation?.toLocaleString()}`
            : unknown_text}
        </IonText>
        <IonGrid className="rental-value-subtext-container">
          <IonText className="rental-value-subtext">
            Call{" "}
            <a className="phone-number rental-value-subtext" href={`tel:${AGENT_PHONE_NUMBER}`}>
              {AGENT_PHONE_NUMBER}
            </a>{" "}
            to speak with an expert
          </IonText>
        </IonGrid>
        <IonRow className="rental-value-button-container">
          <IonCol size="12" className="" sizeMd="5.5">
            <Button
              label={RENTAL_VALUE.buttonTextOne}
              onClick={() => {
                handleMixpanel(REPORT_PAGE_BUTTONS.LIST_MY_PROPERTY);
                window.open(HBS_PAGE_LINKS.LIST_PROPERTY);
              }}
              classes="button-style font_bold"
            />
          </IonCol>
          <IonCol size="12" sizeMd="5.5">
            <Button
              label={RENTAL_VALUE.buttonTextTwo}
              onClick={() => {
                handleMixpanel(REPORT_PAGE_BUTTONS.FREE_IN_PERSON_VALUATION);
                window.open(HBS_PAGE_LINKS.LIST_PROPERTY);
              }}
              classes="button-style font_bold valuation valuation-button-style"
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </DataCard>
  );
};

export default RentalValue;
