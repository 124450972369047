export const CONTACT_DETAILS_TITLE = "Your details for a copy of the report?" as const;
export const INVALID_PHONE_ERROR = "Invalid Mobile number" as const;

export const CONTACT_DETAILS_FIELDS = {
  CONTACT_TYPE: "contactType",
  FIRST_NAME: "firstName",
  SURNAME: "surname",
  EMAIL: "email",
  PHONE: "phone",
  COUNTRY_CODE: "countryCode"
} as const;

export const CONTACT_DETAILS_PLACEHOLDERS = {
  FIRST_NAME: "First Name",
  SURNAME: "Surname",
  EMAIL: "Email",
  CONTACT_TYPE: "Select"
} as const;

export enum CONTACT_TYPES_OPTIONS {
  OWNER = "owner",
  RENTER = "renter"
}

export const CONTACT_TYPES = [
  {
    id: 1,
    label: "Owner",
    value: CONTACT_TYPES_OPTIONS.OWNER
  },
  {
    id: 2,
    label: "Renter",
    value: CONTACT_TYPES_OPTIONS.RENTER
  }
];

export const CONTACT_TYPE_LABEL = "I am the property" as const;

export const PHONE_REQUIRED_ERROR = "Phone is a required field";

export const EMAIL_REQUIRED_ERROR = `${CONTACT_DETAILS_PLACEHOLDERS.EMAIL} is a required field`;
export const EMAIL_INVALID_ERROR = `Provide valid ${CONTACT_DETAILS_FIELDS.EMAIL} address`;
