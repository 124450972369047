import React from "react";
import Button from "@components/Button/Button";
import { IonRow } from "@ionic/react";
import "./ListPropertyBanner.scss";
import { HBS_PAGE_LINKS, LIST_PROPERTY_BANNER } from "../../constants/report.constants";
import Mixpanel from "@utils/mixpanel";
import { MixpanelEventNames } from "@utils/mixpanel/events";
import { JOURNEY_NAME, PAGE_VARIANT, REPORT_PAGE_BUTTONS } from "@utils/mixpanel/constants";
import { useLocation } from "react-router-dom";

interface MixpanelStartForFreeInterface {
  "Journey Name": string;
  "Page Variant": string;
  "Button Name": string;
}
const ListPropertyBanner = () => {
  const location = useLocation();

  const handleButtonClick = () => {
    handleMixpanel();
    window.open(HBS_PAGE_LINKS.GET_STARTED);
  };

  const handleMixpanel = () => {
    const mixpanelData: MixpanelStartForFreeInterface = {
      "Journey Name": JOURNEY_NAME.owner,
      "Page Variant": PAGE_VARIANT.A,
      "Button Name": REPORT_PAGE_BUTTONS.START_FOR_FREE
    };
    Mixpanel(MixpanelEventNames.property_report_page_click_start_for_free, mixpanelData);
  };
  return (
    <IonRow className="list-property-container">
      <IonRow className="heading-container">
        <IonRow>{location.pathname !== "/area-not-covered" && LIST_PROPERTY_BANNER.title}</IonRow>
      </IonRow>
      <IonRow className="adc-sub-heading">
        {location.pathname === "/area-not-covered"
          ? LIST_PROPERTY_BANNER.areaNotCoveredSubTitle
          : LIST_PROPERTY_BANNER.subTitle}
      </IonRow>
      <IonRow className="list-property-sub-container">
        <Button
          label="Start For Free"
          onClick={handleButtonClick}
          classes="button-style font_bold"
        />
      </IonRow>
      <IonRow className="additional-details-container">
        <IonRow className="font_bold white-text">
          <a
            className="white_text"
            href={LIST_PROPERTY_BANNER.whatsapp}
            target="_blank"
            rel="noreferrer"
          >
            {LIST_PROPERTY_BANNER.footerTitle}{" "}
          </a>
        </IonRow>

        <IonRow className="more_info">
          {location.pathname === "/area-not-covered"
            ? LIST_PROPERTY_BANNER.areaNotCoveredfooterSubTitle
            : LIST_PROPERTY_BANNER.footerSubTitle}
        </IonRow>
      </IonRow>
    </IonRow>
  );
};

export default ListPropertyBanner;
