import React from "react";
import { IonGrid, IonCol, IonText } from "@ionic/react";
import Button from "@components/Button/Button";
import "./MarketAnalysis.scss";
import Tabular from "@components/Tabular/Tabular";
import { useAppSelector } from "@hooks";
import {
  HBS_PAGE_LINKS,
  MARKET_ANALYSIS,
  SYMBOLS,
  unknown_text
} from "@constants/report.constants";
import { numberWithCommas } from "@utils/helpers/converter";
import {
  JOURNEY_NAME,
  PAGE_VARIANT,
  REPORT_PAGE_BUTTONS,
  REPORT_PAGE_CARD_TITLES
} from "@utils/mixpanel/constants";
import { MixpanelEventNames } from "@utils/mixpanel/events";
import Mixpanel from "@utils/mixpanel";

interface MixpanelMarketAnalysisInterface {
  "Journey Name": string;
  "Button Pressed": string;
  "Page Variant": string;
  "Page Section": string;
}

const MarketAnalysis = () => {
  const { report } = useAppSelector((state) => state.report);
  const { bedroomDetails } = useAppSelector((state) => state?.valuation);

  const bedroomCount = parseInt(
    report?.valuationresult?.valuationproperty?.bedrooms || bedroomDetails?.value
  );

  const tableData = [
    {
      title: report?.neighbourhoodData?.homes
        ? `${numberWithCommas(report?.neighbourhoodData?.homes)}`
        : unknown_text,
      subTitle: `Home`
    },
    {
      title: report?.neighbourhoodData?.people
        ? `${numberWithCommas(report?.neighbourhoodData?.people)}`
        : unknown_text,
      subTitle: "People"
    },
    {
      title: report?.neighbourhoodData?.owner_occupied_homes
        ? `${report?.neighbourhoodData?.owner_occupied_homes}%`
        : unknown_text,
      subTitle: "owner occupied"
    },
    {
      title:
        report?.neighbourhoodData?.average_age_min && report?.neighbourhoodData?.average_age_max
          ? `${report?.neighbourhoodData?.average_age_min} - ${report?.neighbourhoodData?.average_age_max}`
          : unknown_text,
      subTitle: "Average age"
    },
    {
      title: report?.neighbourhoodData?.homes_with_mortgage
        ? `${report?.neighbourhoodData?.homes_with_mortgage}%`
        : unknown_text,
      subTitle: "with Mortgage"
    },
    {
      title: report?.neighbourhoodData?.average_household_income
        ? `${SYMBOLS.POUND}${numberWithCommas(report?.neighbourhoodData?.average_household_income)}`
        : unknown_text,
      subTitle: "Average Household income"
    },
    {
      title: report?.neighbourhoodData?.average_house_value
        ? `${SYMBOLS.POUND}${numberWithCommas(report?.neighbourhoodData?.average_house_value)}`
        : unknown_text,
      subTitle: "current average value"
    },
    {
      title:
        report?.neighbourhoodData?.days_on_market_min &&
        report?.neighbourhoodData?.days_on_market_max
          ? `${report?.neighbourhoodData?.days_on_market_min} - ${report?.neighbourhoodData?.days_on_market_max} Days`
          : unknown_text,
      subTitle: "Average time on market"
    }
  ];

  const handleMixpanel = (button: string) => {
    const mixpanelData: MixpanelMarketAnalysisInterface = {
      "Journey Name": JOURNEY_NAME.owner,
      "Button Pressed": button,
      "Page Variant": PAGE_VARIANT.A,
      "Page Section": REPORT_PAGE_CARD_TITLES.NEIGHBORHOOD_MARKET_ANALYSIS
    };
    Mixpanel(MixpanelEventNames.property_report_page_main_buttons, mixpanelData);
  };

  const tableJSX = () => {
    return tableData.map((data, index) => (
      <IonCol key={`${index}`}>
        <IonText className="summary-text">
          <span className={data.title === unknown_text ? `grey600_text` : ""}>{data.title}</span>
        </IonText>
        <br />
        <IonText className="summary-sub-text capitalize">{data.subTitle}</IonText>
      </IonCol>
    ));
  };

  return (
    <IonGrid className="market_analysis">
      <IonGrid className="title_container">
        <IonText className="title">{MARKET_ANALYSIS.title}</IonText>
        <IonText className="subtitle">
          {bedroomCount} Bedroom Homes in {report?.neighbourhoodData?.outcode?.toUpperCase()}
        </IonText>
      </IonGrid>
      <IonGrid className="main_content">
        <IonGrid className="left">
          <IonGrid className="left_text_wrapper">
            <IonText>
              {MARKET_ANALYSIS.firstSubContent} {bedroomCount} {MARKET_ANALYSIS.secondSubContent}
            </IonText>
          </IonGrid>
          <IonGrid className="btn_container">
            <Button
              label={"List My Property"}
              onClick={() => {
                handleMixpanel(REPORT_PAGE_BUTTONS.LIST_MY_PROPERTY);
                window.open(HBS_PAGE_LINKS.LIST_PROPERTY);
              }}
            />
            <Button
              label={"Free In-Person Valuation"}
              onClick={() => {
                handleMixpanel(REPORT_PAGE_BUTTONS.FREE_IN_PERSON_VALUATION);
                window.open(HBS_PAGE_LINKS.LIST_PROPERTY);
              }}
              classes={"btn_outline"}
            />
          </IonGrid>
        </IonGrid>
        <IonGrid className="right">
          <Tabular column_style={["table-value"]} colCount={2} data={tableJSX()} />
        </IonGrid>
      </IonGrid>
    </IonGrid>
  );
};

export default MarketAnalysis;
