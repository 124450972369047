import React, { ReactNode } from "react";
import { FONT_SIZE, FONT_WEIGHT } from "@constants/enums/typography";
import { IonCol, IonGrid, IonImg, IonRow, IonText } from "@ionic/react";
import { getImagePath } from "@utils/helpers/media.helper";
import "./TestimonialCard.scss";

interface TestimonialCardInterface {
  rating: 0 | 1 | 2 | 3 | 4 | 5;
  text: string;
  author: string;
}

const TestimonialCard = ({ rating, text, author }: TestimonialCardInterface) => {
  const renderRatingStar = (rating: number) => {
    const jsx: ReactNode[] = [];
    const filledStars: number = rating > 5 ? 5 : rating;
    const blankStars: number = 5 - filledStars;

    if (filledStars) {
      [...Array.from(Array(filledStars).keys())].forEach((index) => {
        jsx.push(
          <IonImg
            key={index}
            className="testimonial_stars"
            src={getImagePath(`svg/star_solid.svg`)}
          />
        );
      });
    }
    if (blankStars) {
      [...Array.from(Array(blankStars).keys())].forEach((index) => {
        jsx.push(
          <IonImg
            key={index}
            className="testimonial_stars"
            src={getImagePath(`svg/star_grey.svg`)}
          />
        );
      });
    }
    return jsx;
  };

  return (
    <IonGrid className="testimonial_card">
      <IonRow>
        <IonCol className="testimonial_stars_wrapper mb_24" size="12">
          {renderRatingStar(rating)}
        </IonCol>
        <IonCol className="mb_16" size="12">
          <IonText className={`${FONT_SIZE.text_sm} ${FONT_WEIGHT.font_medium}`}>{text}</IonText>
        </IonCol>
        <IonCol className="testimonials_author" size="12">
          <IonImg className="testimonial_dot" src={getImagePath(`svg/dot.svg`)} />
          <IonText className={`${FONT_SIZE.text_sm} ${FONT_WEIGHT.font_medium}`}>{author}</IonText>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default TestimonialCard;
