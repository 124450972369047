import { BEDROOM_COUNTS, PROPERTY_AVAILABILITY_ENUM, STEPS } from "@constants/home.constants";
import { Dispatch } from "@reduxjs/toolkit";
import {
  setAddress,
  setBedroomCount,
  setContactDetails,
  setPropertyAvailability,
  setPropertySubTypeValue,
  setPropertyTypeValue,
  resetState
} from "@store/valuation/valuationSlice";
import { NavigateFunction } from "react-router-dom";

export const populateStateFromQueryStepsData = (
  queryParams: URLSearchParams,
  dispatch: Dispatch<any>,
  navigate: NavigateFunction
) => {
  try {
    const previousSteps = JSON.parse(atob(queryParams.get("previousSteps") || "[]"));
    if (!previousSteps.length) return navigate("/home");
    previousSteps.forEach((step: string) => {
      const decryptedStepData = atob(queryParams.get(step) || "");
      if (step === STEPS.ADDRESS) {
        dispatch(setAddress(JSON.parse(decryptedStepData)));
      } else if (step === STEPS.PROPERTY_TYPE) {
        dispatch(setPropertyTypeValue(decryptedStepData));
      } else if (step === STEPS.PROPERTY_SUB_TYPE) {
        dispatch(setPropertySubTypeValue(parseInt(decryptedStepData)));
      } else if (step === STEPS.BEDROOMS) {
        dispatch(
          setBedroomCount({
            label: decryptedStepData,
            value: BEDROOM_COUNTS[decryptedStepData]
          })
        );
      } else if (step === STEPS.CONTACT_DETAILS) {
        dispatch(setContactDetails(JSON.parse(decryptedStepData)));
      } else if (step === STEPS.PROPERTY_AVAILABILITY) {
        dispatch(
          setPropertyAvailability({
            label: PROPERTY_AVAILABILITY_ENUM[decryptedStepData],
            value: decryptedStepData
          })
        );
      }
    });
  } catch (err) {
    console.log("Error ->", err);
    dispatch(resetState());
    navigate("/home");
  }
};
