import React, { useRef } from "react";
import TestimonialCard from "@components/TestimonialCard/TestimonialCard";
import { IonGrid, IonSlides, IonSlide } from "@ionic/react";
import { TESTIMONIAL_DATA } from "@constants/report.constants";
import { useWindowDimensions } from "@hooks/useWindowDimensions";
import Button from "@components/Button/Button";
import "./Testimonials.scss";

const Testimonials = () => {
  const mySlides = useRef<HTMLIonSlidesElement>(null);
  const { isMobile, isDesktop } = useWindowDimensions();

  const getSlideOptions = () => {
    const defaultOptions: any = {
      loop: true,
      initialSlide: 0,
      speed: 400,
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 60
        },
        900: {
          slidesPerView: 3,
          spaceBetween: 20
        },
        1050: {
          slidesPerView: 4,
          spaceBetween: 40
        },
        1440: {
          slidesPerView: 5,
          spaceBetween: 40
        }
      }
    };
    if (isMobile) {
      return {
        ...defaultOptions,
        centeredSlides: true
      };
    } else {
      return {
        ...defaultOptions
      };
    }
  };

  const onBtnClicked = async (direction: string) => {
    const swipes = await mySlides.current?.getSwiper();
    if (direction === "next") {
      swipes.slideNext();
    } else if (direction === "prev") {
      swipes.slidePrev();
    }
  };
  return (
    <IonGrid>
      <IonSlides
        pager={isMobile}
        options={getSlideOptions()}
        className="testimonials_slider"
        ref={mySlides}
      >
        {TESTIMONIAL_DATA.map((data) => (
          <IonSlide key={data.author}>
            <TestimonialCard text={data.text} rating={data.rating} author={data.author} />
          </IonSlide>
        ))}
      </IonSlides>

      {isDesktop && (
        <>
          <span className="prev_span"></span>
          <span className="next_span"></span>
        </>
      )}

      <Button classes="testimonials_button prev" label={""} onClick={() => onBtnClicked("prev")} />
      <Button classes="testimonials_button next" label={""} onClick={() => onBtnClicked("next")} />
    </IonGrid>
  );
};

export default Testimonials;
