export const JOURNEY_NAME = {
  owner: "Owner"
} as const;
export const PAGE_VARIANT = {
  A: "A"
} as const;
export const NEXT_BUTTON_CLICK_TYPE = {
  automatic: "Auto next",
  manual: "Next",
  back: "Back"
} as const;

export const REPORT_PAGE_BUTTONS = {
  START_FOR_FREE: "Start for Free",
  LIST_MY_PROPERTY: "List my Property",
  FREE_IN_PERSON_VALUATION: "Free in-person valuation"
} as const;

export const REPORT_PAGE_CARD_TITLES = {
  ESTIMATED_RENTAL_VALUE: "Your Estimated Rental Value",
  ESTIMATED_RENTAL_RANGE: "Your Estimated Rental Range",
  PROPERTY_SITS_IN_MARKET: "Where Your Property Sits In The Market",
  NEIGHBORHOOD_MARKET_ANALYSIS: "Your Neighbourhood & Market Analysis"
} as const;
