import { IN_PERSON_VALUATION_CTA } from "./report.constants";

export const HEADER_LIST_LABELS = {
  PHONE_NUMBER: {
    LABEL: "020 8530 9045",
    IDENTIFIER: "PHONE_NUMBER"
  },
  LIST_PROPERTY: {
    LABEL: "List A Property",
    IDENTIFIER: "LIST_PROPERTY"
  },
  RENTAL_VALUATION: {
    LABEL: "Rental Valuation",
    IDENTIFIER: "RENTAL_VALUATION"
  },
  SIGN_IN: {
    LABEL: "Sign In",
    IDENTIFIER: "SIGN_IN"
  },
  PL_LOGO: {
    LABEL: "PL_LOGO"
  }
};

export const HEADER_LIST_LINKS = {
  PHONE_NUMBER: "tel:02085309045",
  SIGN_IN: "https://app.propertyloop.co.uk/signup?utm_source=pl_valuation_report",
  RENTAL_VALUATION: IN_PERSON_VALUATION_CTA,
  PL_LOGO: "https://launch.propertyloop.co.uk/",
  LIST_PROPERTY: "https://app.propertyloop.co.uk/signup?utm_source=pl_valuation_report"
};
