import React, { ReactNode } from "react";
import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import "./DataCard.scss";
import { arrayToStringClassName } from "@utils/helpers/converter";

interface DataCardInterface {
  title: {
    titleText: string;
    titleFontType: string[];
  };
  children: ReactNode;
  footer?: {
    hasDivider: boolean;
    footerText: string;
    footerFontType: string[];
  };
}

const DataCard = ({ title, footer, children }: DataCardInterface) => {
  const { titleText, titleFontType } = title;
  return (
    <IonGrid className="datacard_wrapper">
      <IonRow>
        <IonCol>
          <IonText
            className={`card_main_title ${
              titleFontType.length ? arrayToStringClassName(titleFontType) : ""
            }`}
          >
            {titleText}
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow className="datacard_body">{children}</IonRow>
      <IonRow className="datacard_footer">
        {footer && footer.hasDivider && <hr className="datacard_footer_divider" />}
        {footer && footer.footerText && (
          <IonText
            className={`${
              footer.footerFontType.length ? arrayToStringClassName(footer.footerFontType) : ""
            }`}
          >
            {footer.footerText}
          </IonText>
        )}
      </IonRow>
    </IonGrid>
  );
};

export default DataCard;
