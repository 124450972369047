import mixpanel from "mixpanel-browser";
import LogRocket from "logrocket";
import { ENVIRONMENT } from "@constants/env.constants";
import { MixpanelEventNames, MixpanelEventNamesTypeInterface } from "./events";
import { JOURNEY_NAME, PAGE_VARIANT } from "./constants";
import {
  mixpanelBedroomCountPageEvent,
  mixpanelContactDetailsPageEvent,
  mixpanelDefaultTypePageEvent,
  mixpanelInvalidPhoneNumberEvent,
  mixpanelRentalValueReportPageEvent,
  mixpanelStartForAvailabilityPageEvent,
  mixpanelStartForFreeReportPageEvent,
  mixpanelTextLinkReportPageEvent,
  mixpanelViewReportPageEvent,
  mixpanelViewValuationPageEvent,
  mixpanelViewVBuildingKindPageEvent,
  mixpanelViewVBuildingTypePageEvent
} from "./helpers";
import {
  MixpanelInvalidPhoneNumberEventInterface,
  MixpanelPropertyAvailabilityInterface,
  MixpanelPropertySubTypeInterface
} from "./mixpanel.types";

const isEnabled = Number(process.env.REACT_APP_ENABLE_MIXPANEL || "");

isEnabled === 1 &&
  mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`, {
    cross_subdomain_cookie: false,
    debug: ENVIRONMENT === "development",
    loaded: () => {
      LogRocket.getSessionURL((url) => {
        if (url) {
          mixpanel.track("LogRocket", {
            LogRocketSessionUrl: url
          });
        }
      });
    }
  });

const Mixpanel = (
  eventName: MixpanelEventNamesTypeInterface,
  data:
    | MixpanelInvalidPhoneNumberEventInterface
    | MixpanelPropertySubTypeInterface
    | MixpanelPropertyAvailabilityInterface = {
    "Page Variant": PAGE_VARIANT.A,
    "Journey Name": JOURNEY_NAME.owner
  }
) => {
  switch (eventName) {
    case MixpanelEventNames.view_valuation_home_page: {
      mixpanelViewValuationPageEvent(eventName, data);
      break;
    }
    case MixpanelEventNames.pl_valuation_building_type_selection: {
      mixpanelViewVBuildingTypePageEvent(eventName, data);
      break;
    }
    case MixpanelEventNames.pl_valuation_bedroom_number_selection: {
      mixpanelBedroomCountPageEvent(eventName, data);
      break;
    }
    case MixpanelEventNames.pl_valuation_enter_user_details: {
      mixpanelContactDetailsPageEvent(eventName, data);
      break;
    }

    case MixpanelEventNames.pl_valuation_building_kind_selection: {
      mixpanelViewVBuildingKindPageEvent(eventName, data);
      break;
    }
    case MixpanelEventNames.view_property_report_page: {
      mixpanelViewReportPageEvent(eventName, {
        "Page Variant": PAGE_VARIANT.A,
        "Journey Name": JOURNEY_NAME.owner
      });
      break;
    }
    case MixpanelEventNames.property_report_page_click_start_for_free: {
      mixpanelStartForFreeReportPageEvent(eventName, data);
      break;
    }
    case MixpanelEventNames.pl_valuation_availability: {
      mixpanelStartForAvailabilityPageEvent(eventName, data);
      break;
    }
    case MixpanelEventNames.property_report_page_main_buttons: {
      mixpanelRentalValueReportPageEvent(eventName, data);
      break;
    }
    case MixpanelEventNames.property_report_page_click_links: {
      mixpanelTextLinkReportPageEvent(eventName, data);
      break;
    }
    case MixpanelEventNames.user_details_invalid_phone_number: {
      mixpanelInvalidPhoneNumberEvent(eventName, data);
      break;
    }
    default: {
      mixpanelDefaultTypePageEvent(eventName, data);
      break;
    }
  }
};

export default Mixpanel;
