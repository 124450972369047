export const addPreviousStep = (step: string, queryParams: URLSearchParams) => {
  const previousSteps = queryParams.get("previousSteps");
  let decryptedPreviousSteps: Array<string> = [];
  if (previousSteps) decryptedPreviousSteps = JSON.parse(atob(previousSteps));

  if (decryptedPreviousSteps[decryptedPreviousSteps.length - 1] !== step)
    decryptedPreviousSteps.push(step);

  return btoa(JSON.stringify(decryptedPreviousSteps));
};

export const removeLastStep = (step: string, queryParams: URLSearchParams) => {
  const previousSteps = queryParams.get("previousSteps");
  let decryptedPreviousSteps: Array<string> = [];
  if (previousSteps) decryptedPreviousSteps = JSON.parse(atob(previousSteps));

  if (decryptedPreviousSteps[decryptedPreviousSteps.length - 1] === step)
    decryptedPreviousSteps.splice(decryptedPreviousSteps.length - 1);

  return btoa(JSON.stringify(decryptedPreviousSteps));
};
