import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import apiService from "@utils/apiService";
import { GetReportPayload, ReportPayload, ReportState } from "./types";

// initial state
const initialState: ReportState = {
  loading: false,
  report: null,
  error: ""
};

export const downloadReport = createAsyncThunk(
  "report/download",
  async (payload: ReportPayload, thunkAPI) => {
    try {
      const validatePostcodeResponse = await apiService.get(
        `/v1/properties/validate-postcode?postcode=${payload.property.postcoderAddress.postcode}`
      );
      if (validatePostcodeResponse.data.isPostcodeValid) {
        const response = await apiService.post("/v1/properties/valuation", { ...payload });
        if (response.status !== 201) throw new Error(response.statusText);
        return response.data;
      } else if (payload.navigate) {
        await apiService.post("/v1/properties/valuation-owner-outside-london", { ...payload });
        return {
          navigateTo: "/area-not-covered?src2=valtool"
        };
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({ err });
    }
  }
);

export const getReport = createAsyncThunk(
  "report/get",
  async (payload: GetReportPayload, thunkAPI) => {
    try {
      const response = await apiService.get(`/v1/properties/valuation/${payload.valuationId}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ err });
    }
  }
);

export const ReportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(downloadReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(downloadReport.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.report = action.payload;
    });
    builder.addCase(downloadReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getReport.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.report = action.payload;
    });
    builder.addCase(getReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  }
});

// export actions from slice if any reducers created
// export const {} = ReportSlice.actions;

export default ReportSlice.reducer;
