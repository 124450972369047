import { FORM_TITLE_TYPE, OPTION_TYPE } from "./propertyType.interface";
export const PROPERTY_TYPES = [
  {
    label: "House"
  },
  {
    label: "Flat"
  },

  {
    label: "Bungalow"
  }
];

export const FLAT_TYPES = [
  {
    label: "Purpose Built",
    value: 12
  },
  {
    label: "Converted",
    value: 11
  }
];
export const PROPERTY_AVAILABILITY = [
  {
    label: "Now"
  },
  {
    label: "Within 3 Months"
  },
  {
    label: "3-6 Months"
  },
  {
    label: "6+ Months"
  }
];

export const HOUSE_TYPES = [
  {
    label: "Detached",
    value: 8
  },
  {
    label: "Semi-Detached",
    value: 7
  },
  {
    label: "Mid-Terrace",
    value: 6
  },
  {
    label: "End-Terrace",
    value: 5
  }
];
export const BUNGALOW_TYPES = [
  {
    label: "Detached",
    value: 10
  },
  {
    label: "Semi-Detached",
    value: 9
  },
  {
    label: "Mid-Terrace",
    value: 18
  },
  {
    label: "End-Terrace",
    value: 17
  }
];

export const OPTIONS_TYPES: OPTION_TYPE = {
  PropertyType: PROPERTY_TYPES,
  Bungalow: BUNGALOW_TYPES,
  House: HOUSE_TYPES,
  Flat: FLAT_TYPES,
  PROPERTY_AVAILABILITY
};
export const FORM_TITLE: FORM_TITLE_TYPE = {
  PropertyType: "What type of property do you have ?",
  Bungalow: "What kind of bungalow is it?",
  House: "What kind of House is it?",
  Flat: "What kind of flat is it?",
  PROPERTY_AVAILABILITY: "Lastly, When is your property available?"
};
