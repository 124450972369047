import { REACT_APP_LOGGING_ENABLED, REACT_APP_LOGGING_LEVEL } from "@constants/env.constants";

const setupLogger = () => {
  if (REACT_APP_LOGGING_ENABLED === "1") {
    console.error = () => false;
    console.warn = () => false;
    console.debug = () => false;
    console.info = () => false;
    console.log = () => false;
  } else if (REACT_APP_LOGGING_LEVEL === "error") {
    console.warn = () => false;
    console.debug = () => false;
    console.info = () => false;
    console.log = () => false;
  } else if (REACT_APP_LOGGING_LEVEL === "warn") {
    console.debug = () => false;
    console.info = () => false;
    console.log = () => false;
  } else if (REACT_APP_LOGGING_LEVEL === "log") {
    return false;
  }
};

export default setupLogger;
