import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { PERSIST, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import valuationReducer from "./valuation/valuationSlice";
import reportReducer from "./report/reportSlice";

const reducers = combineReducers({
  valuation: valuationReducer,
  report: reportReducer
});

const persistConfig = {
  key: "root",
  storage: storageSession
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: { ignoredActions: [PERSIST] } })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
