import { ReportPayload } from "@store/report/types";
import { ValuationState } from "@store/valuation/valuationSlice";

export const validateValuationPayload = (data: ReportPayload) =>
  Boolean(
    data.lead.firstName &&
      data.lead.lastName &&
      data.lead.email &&
      data.lead.phone &&
      data.lead.countryCode &&
      data.property.bedrooms &&
      data.property.postcoderAddress.postcode &&
      data.property.propertyAvailability &&
      data.property.propertyType
  );

export const validateContactDetailsUserDropOffHSPayload = (data: ReportPayload) =>
  Boolean(
    data.lead.firstName &&
      data.lead.lastName &&
      data.lead.email &&
      data.lead.phone &&
      data.lead.countryCode &&
      data.lead.role &&
      data.property.bedrooms &&
      data.property.postcoderAddress.postcode &&
      data.property.propertyType &&
      data.property.propertyTypeName
  );

export const generateValuationPayload = (formData: ValuationState, hasUserDroppedOff = false) => {
  const payload: ReportPayload = {
    property: {
      postcoderAddress: formData.address,
      propertyType: formData.propertySubType,
      bedrooms: formData.bedroomDetails.value,
      propertyAvailability: formData.propertyAvailabilityDetails.value,
      propertyTypeName: formData.propertyType,
      isInLondon: formData.isInLondon
    },
    lead: {
      firstName: formData.contactDetails?.firstName,
      lastName: formData.contactDetails?.surname,
      email: formData.contactDetails?.email,
      countryCode: formData.contactDetails?.countryCode,
      phone: formData.contactDetails?.phone
    }
  };

  if (hasUserDroppedOff) {
    const updatedPayload = {
      ...payload,
      ...(hasUserDroppedOff && {
        lead: {
          ...payload.lead,
          role: formData.contactDetails?.contactType
        }
      })
    };
    return updatedPayload;
  }

  return payload;
};
