import {
  REACT_APP_LIST_PROPERTY_FORM_URL,
  REACT_APP_RENTER_FORM_URL
} from "@constants/env.constants";
const getVarianceFactor = (confidenceLevel: number) => {
  if (confidenceLevel > 5.5) return 0.05;
  if (confidenceLevel > 4.5) return 0.1;
  if (confidenceLevel > 3.5) return 0.15;
  return 0.17;
};

export const getMinimumRentValuation = (rentValuation: number, confidenceLevel: number) => {
  return rentValuation - getVarianceFactor(confidenceLevel) * rentValuation;
};

export const getMaximumRentValuation = (rentValuation: number, confidenceLevel: number) => {
  return rentValuation + getVarianceFactor(confidenceLevel) * rentValuation;
};

export const openListMyPropertyForm = (formData: any, isSameWindow = false) => {
  if (isSameWindow)
    openLinkInSameTab(createURLToOpenForm(formData, REACT_APP_LIST_PROPERTY_FORM_URL));
  else window.open(createURLToOpenForm(formData, REACT_APP_LIST_PROPERTY_FORM_URL));
};

export const openRenterForm = (formData: any, isSameWindow = false) => {
  if (isSameWindow) openLinkInSameTab(createURLToOpenForm(formData, REACT_APP_RENTER_FORM_URL));
  else window.open(createURLToOpenForm(formData, REACT_APP_RENTER_FORM_URL));
};

const generateQueryParams = (formData: any) => {
  let paramsStr = "";

  Object.entries(formData).forEach((data) => {
    if (paramsStr.includes("?")) {
      data[1] &&
        (paramsStr += `&${data[0]}=${encodeURIComponent(data[1] as string | number | boolean)}`);
    } else {
      data[1] &&
        (paramsStr += `?${data[0]}=${encodeURIComponent(data[1] as string | number | boolean)}`);
    }
  });
  return paramsStr;
};

export const createURLToOpenForm = (formData: any, baseUrl?: string) => {
  const paramsStr = generateQueryParams(formData);
  return `${baseUrl}${paramsStr}`;
};

export const openLinkInSameTab = (url: string) => {
  window.open(url, "_self");
};

export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    return (timeout = setTimeout(() => func(...args), waitFor));
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};
