export enum FONT_SIZE {
  text_xs = "text_xs", // 12px
  text_sm = "text_sm", // 14px
  text_base = "text_base", // 16px
  text_lg = "text_lg", // 18px
  text_xl = "text_xl", // 20px
  text_2xl = "text_2xl", // 22px
  text_3xl = "text_3xl", // 24px
  text_4xl = "text_4xl", // 28px
  text_5xl = "text_5xl", // 32px
  text_6xl = "text_6xl" // 36px
}

export enum FONT_COLOR {
  black_text_imp = "black_text_imp",
  grey600_text = "grey600_text"
}

export enum FONT_WEIGHT {
  font_thin = "font_thin",
  font_extralight = "font_extralight",
  font_light = "font_light",
  font_normal = "font_normal",
  font_medium = "font_medium",
  font_semibold = "font_semibold",
  font_bold = "font_bold",
  font_extrabold = "font_extrabold",
  font_black = "font_black"
}

export enum FONT_TYPE {
  capitalize = "capitalize"
}
