import React, { useEffect, useState } from "react";
import { IonGrid, IonText } from "@ionic/react";
import Button from "@components/Button/Button";
import {
  PROPERTY_AVAILABILITY_ENUM,
  STEPS,
  PROPERTY_VALUATION_ROUTES,
  PROPERTY_VALUATION_FORM_TITLE,
  BUTTON_LABELS
} from "@constants/home.constants";
import "./StepPropertyAvailability.scss";
import { useAppDispatch, useAppSelector, useWindowDimensions } from "@hooks";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import {
  resetPropertyAvailability,
  sendValuationReportLink,
  setPropertyAvailability
} from "@store/valuation/valuationSlice";
import Mixpanel from "@utils/mixpanel";
import { MixpanelEventNames } from "@utils/mixpanel/events";
import { JOURNEY_NAME, NEXT_BUTTON_CLICK_TYPE, PAGE_VARIANT } from "@utils/mixpanel/constants";
import { MixpanelPropertyAvailabilityInterface } from "@utils/mixpanel/mixpanel.types";
import { CONTACT_TYPES_OPTIONS } from "@constants/home.contactDetails.constants";
import { generateValuationPayload, validateValuationPayload } from "@utils/helpers/data.helper";
import { openRenterForm } from "@utils/helpers";
import apiService from "@utils/apiService";
import { isIOS } from "react-device-detect";
import { populateStateFromQueryStepsData } from "@utils/helpers/populate-state-from-query.helper";
import { addPreviousStep, removeLastStep } from "@utils/helpers/add-query-step.helper";
import Loader from "@components/Loader";

const StepPropertyAvailability = () => {
  const [isLoading, setIsLoading] = useState(false);
  const availabilityDuration = Object.keys(PROPERTY_AVAILABILITY_ENUM);
  const Availability = useAppSelector((state) => state.valuation.propertyAvailabilityDetails);
  const contactType = useAppSelector((state) => state.valuation.contactDetails.contactType);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const firstName = useAppSelector((state) => state.valuation?.contactDetails?.firstName);
  const email = useAppSelector((state) => state.valuation?.contactDetails?.email);
  const phone = useAppSelector((state) => state.valuation?.contactDetails?.phone);

  const formData = useAppSelector((state) => state.valuation);
  const propertyAvailabilityDetails = formData.propertyAvailabilityDetails;

  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    populateStateFromQueryStepsData(searchParams, dispatch, navigate);
  }, []);

  const handleBack = () => {
    dispatch(resetPropertyAvailability());
    searchParams.set("previousSteps", removeLastStep(STEPS.PROPERTY_AVAILABILITY, searchParams));
    searchParams.delete(STEPS.PROPERTY_AVAILABILITY);
    searchParams.set("step", STEPS.CONTACT_DETAILS);
    setSearchParams(searchParams);
    sendMixPanelData(Availability.label, NEXT_BUTTON_CLICK_TYPE.back);
  };

  const handleNavigation = async (
    propertyAvailableLabel?: string,
    propertyAvailableValue?: string
  ) => {
    if (contactType === CONTACT_TYPES_OPTIONS.RENTER) {
      // Use the below form object to prefill the hubspot form
      const form = {};

      const tempFormData = {
        ...formData,
        propertyAvailabilityDetails: {
          label: propertyAvailableLabel || propertyAvailabilityDetails.label,
          value: propertyAvailableValue || propertyAvailabilityDetails.value
        }
      };
      const payload = generateValuationPayload(tempFormData);
      if (!validateValuationPayload(payload)) {
        navigate("/home");
        return;
      }
      setIsLoading(true);
      await apiService.post(`/v1/properties/valuation-renter`, { ...payload });
      setIsLoading(false);
      openRenterForm(form, true);
    } else {
      searchParams.delete("step");
      const reportUrl = searchParams.toString();
      setIsLoading(true);
      await dispatch(sendValuationReportLink({ firstName, email, phone, reportUrl }));
      setIsLoading(false);
      navigate({ pathname: PROPERTY_VALUATION_ROUTES.EMAIL_SENT, search: searchParams.toString() });
    }
  };

  const handleNext = () => {
    searchParams.set("previousSteps", addPreviousStep(STEPS.PROPERTY_AVAILABILITY, searchParams));
    handleNavigation();
    sendMixPanelData(Availability.label, NEXT_BUTTON_CLICK_TYPE.manual);
  };

  const setAvailability = (key: string) => {
    dispatch(
      setPropertyAvailability({
        label: PROPERTY_AVAILABILITY_ENUM[key],
        value: key
      })
    );
    searchParams.set("previousSteps", addPreviousStep(STEPS.PROPERTY_AVAILABILITY, searchParams));
    const encryptedAvailabilityData = btoa(key);
    searchParams.set(STEPS.PROPERTY_AVAILABILITY, encryptedAvailabilityData);
    setSearchParams(searchParams);
    sendMixPanelData(PROPERTY_AVAILABILITY_ENUM[key], NEXT_BUTTON_CLICK_TYPE.automatic);
    handleNavigation(PROPERTY_AVAILABILITY_ENUM[key], key);
  };

  const sendMixPanelData = (availability: string, buttonPressed: string) => {
    const mixpanelData: MixpanelPropertyAvailabilityInterface = {
      "Journey Name": JOURNEY_NAME.owner,
      Availability: availability,
      "Button Pressed": buttonPressed,
      "Page Variant": PAGE_VARIANT.A
    };

    Mixpanel(MixpanelEventNames.pl_valuation_availability, mixpanelData);
  };

  useEffect(() => {
    if (isIOS) {
      document.getElementById("availability_form")?.scrollIntoView(true);
    } else if (isMobile) {
      document
        .getElementById("availability_form")
        ?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <IonGrid className="property-availability" id="availability_form">
          <Loader customClass="loader" />
        </IonGrid>
      ) : (
        <IonGrid className="property-availability" id="availability_form">
          <IonGrid className="title-container">
            <IonText className="title">
              {PROPERTY_VALUATION_FORM_TITLE.PROPERTY_AVAILABILITY}
            </IonText>
          </IonGrid>
          <IonGrid className="container">
            {availabilityDuration.map((durationType: string) => {
              return (
                <Button
                  key={durationType}
                  classes={`form_button ${
                    Availability?.label === PROPERTY_AVAILABILITY_ENUM[durationType]
                      ? "selectedButton"
                      : ""
                  }`}
                  label={PROPERTY_AVAILABILITY_ENUM[durationType]}
                  onClick={() => setAvailability(durationType)}
                ></Button>
              );
            })}
          </IonGrid>
          <IonGrid className="navigation_main_container">
            <IonGrid className="navigation_container">
              <Button label={BUTTON_LABELS.BACK} classes="navigation" onClick={handleBack} />
              <Button
                label={BUTTON_LABELS.NEXT}
                classes={`${Availability?.value ? "navigation" : "navigation_disabled"}`}
                disabled={Availability?.label ? false : true}
                onClick={handleNext}
              />
            </IonGrid>
          </IonGrid>
        </IonGrid>
      )}
    </>
  );
};

export default StepPropertyAvailability;
