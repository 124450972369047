import { ENVIRONMENT } from "@constants/env.constants";
import { IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./ErrorMessage.scss";

interface ErrorProps {
  error?: string | null;
  showBackBtn?: boolean;
}

const ErrorMessage = ({ error, showBackBtn = false }: ErrorProps) => {
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }

  return (
    <IonRow className="w-100 justify-content-center danger_text">
      The system has encountered an error, please try refreshing the page
      <IonGrid className="error-details">{ENVIRONMENT !== "production" && error}</IonGrid>
      <br />
      {showBackBtn && (
        <IonGrid className="redirect-btn" onClick={goBack}>
          Back
        </IonGrid>
      )}
    </IonRow>
  );
};

export default ErrorMessage;
