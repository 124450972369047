import React from "react";
import DataCard from "@components/DataCard/DataCard";
import { FONT_COLOR, FONT_SIZE, FONT_TYPE, FONT_WEIGHT } from "@constants/enums/typography";
import {
  HBS_PAGE_LINKS,
  IN_PERSON_VALUATION_CTA,
  RENTAL_RANGE,
  SYMBOLS
} from "@constants/report.constants";
import { IonGrid, IonRow, IonText } from "@ionic/react";
import { getImagePath } from "@utils/helpers/media.helper";
import "./RentRange.scss";
import { useAppSelector } from "@hooks";
import { getMinimumRentValuation, getMaximumRentValuation } from "@utils/helpers";
import { JOURNEY_NAME, PAGE_VARIANT, REPORT_PAGE_CARD_TITLES } from "@utils/mixpanel/constants";
import Mixpanel from "@utils/mixpanel";
import { MixpanelEventNames } from "@utils/mixpanel/events";
import { MixpanelRentalValueReportPageInterface } from "@utils/mixpanel/mixpanel.types";

const RentRange = () => {
  const selector = useAppSelector((state) => state.report.report);
  const marketRentValuation = parseInt(selector?.valuationresult?.marketrentvaluation);
  const marketRentConfidenceLevel = parseFloat(
    selector?.valuationresult?.marketrentconfidencelevel
  );
  const minimumRentValuation = getMinimumRentValuation(
    marketRentValuation,
    marketRentConfidenceLevel
  );
  const maximumRentValuation = getMaximumRentValuation(
    marketRentValuation,
    marketRentConfidenceLevel
  );

  const handleMixpanel = (button: string) => {
    const mixpanelData: MixpanelRentalValueReportPageInterface = {
      "Journey Name": JOURNEY_NAME.owner,
      "Link Pressed": button,
      "Page Variant": PAGE_VARIANT.A,
      "Target URL": IN_PERSON_VALUATION_CTA,
      "Page Section": REPORT_PAGE_CARD_TITLES.ESTIMATED_RENTAL_RANGE
    };
    Mixpanel(MixpanelEventNames.property_report_page_click_links, mixpanelData);
  };

  return (
    <DataCard
      title={{
        titleText: RENTAL_RANGE.title,
        titleFontType: [FONT_SIZE.text_6xl, FONT_WEIGHT.font_medium, FONT_TYPE.capitalize]
      }}
      footer={{
        hasDivider: true,
        footerText: RENTAL_RANGE.footer,
        footerFontType: [FONT_SIZE.text_xs, FONT_WEIGHT.font_medium, FONT_COLOR.grey600_text]
      }}
    >
      <IonGrid className="rental-range-container">
        <IonRow className="rental-range">
          <IonRow className="rental-range-text-container">
            <IonRow className="rental-range-text-sub-container">
              <IonText className="rental-range-text">
                {SYMBOLS.POUND}
                {Math.round(minimumRentValuation)?.toLocaleString()}
              </IonText>
              <img src={getImagePath(`svg/down-arrow.svg`)} alt="arrow" width="15px" />
            </IonRow>
            <IonRow className="rental-range-text-sub-container">
              <IonText className="rental-range-text">
                {SYMBOLS.POUND}
                {Math.round(maximumRentValuation)?.toLocaleString()}
              </IonText>
              <img src={getImagePath(`svg/down-arrow.svg`)} alt="arrow" width="15px" />
            </IonRow>
          </IonRow>
          <IonRow className="rental-range-line">
            <IonRow className="rental-range-line-left"></IonRow>
            <IonRow className="rental-range-line-center"></IonRow>
            <IonRow className="rental-range-line-right"></IonRow>
          </IonRow>
        </IonRow>
        <IonGrid className="rental-range-subtext-container">
          <IonText
            className="rental-range-subtext cursor"
            onClick={() => {
              handleMixpanel(RENTAL_RANGE.subFooter);
              window.open(HBS_PAGE_LINKS.LIST_PROPERTY);
            }}
          >
            {RENTAL_RANGE.subFooter}
          </IonText>
        </IonGrid>
      </IonGrid>
    </DataCard>
  );
};

export default RentRange;
