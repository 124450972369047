// imports
import mixpanel from "mixpanel-browser";

// local imports
import { MixpanelEventNamesTypeInterface } from "./events";
import {
  MixpanelInvalidPhoneNumberEventInterface,
  MixpanelPropertyAvailabilityInterface,
  MixpanelPropertySubTypeInterface,
  MixpanelPropertyTypeInterface,
  MixpanelRentalValueReportPageInterface
} from "./mixpanel.types";

/**
 * This function sends event for view_valuation_home_page
 *
 */
export const mixpanelViewValuationPageEvent = async (
  eventName: MixpanelEventNamesTypeInterface,
  data: {
    "Page Variant": string;
    "Journey Name": string;
  }
) => {
  mixpanel.track(eventName, {
    ...data
  });
};

/**
 *
 * @param eventName
 * @param data
 */
export const mixpanelViewVBuildingTypePageEvent = async (
  eventName: MixpanelEventNamesTypeInterface,
  data: MixpanelPropertyTypeInterface
) => {
  mixpanel.track(eventName, {
    ...data
  });
};

/**
 * PL Valuation - Building kind Selection
 * @param eventName
 * @param data
 */
export const mixpanelViewVBuildingKindPageEvent = async (
  eventName: MixpanelEventNamesTypeInterface,
  data: MixpanelPropertySubTypeInterface
) => {
  mixpanel.track(eventName, {
    ...data
  });
};

/**
 *
 * @param eventName
 * @param data
 */
export const mixpanelBedroomCountPageEvent = async (
  eventName: MixpanelEventNamesTypeInterface,
  data: {
    "Page Variant": string;
    "Journey Name": string;
  }
) => {
  mixpanel.track(eventName, {
    ...data
  });
};

/**
 *
 * @param eventName
 * @param data
 */
export const mixpanelContactDetailsPageEvent = async (
  eventName: MixpanelEventNamesTypeInterface,
  data: {
    "Page Variant": string;
    "Journey Name": string;
  }
) => {
  mixpanel.track(eventName, {
    ...data
  });
};

/**
 * This function sends event for view_report_home_page
 *
 */
export const mixpanelViewReportPageEvent = async (
  eventName: MixpanelEventNamesTypeInterface,
  data: {
    "Page Variant": string;
    "Journey Name": string;
  }
) => {
  mixpanel.track(eventName, {
    ...data
  });
};

/**
 *
 * @param eventName
 * @param data
 */
export const mixpanelDefaultTypePageEvent = async (
  eventName: MixpanelEventNamesTypeInterface,
  data: MixpanelPropertySubTypeInterface
) => {
  mixpanel.track(eventName, {
    ...data
  });
};

/**
 *
 * @param eventName
 * @param data
 */
export const mixpanelStartForFreeReportPageEvent = async (
  eventName: MixpanelEventNamesTypeInterface,
  data: {
    "Page Variant": string;
    "Journey Name": string;
  }
) => {
  mixpanel.track(eventName, {
    ...data
  });
};

/**
 * PL Valuation - Availability
 * @param eventName
 * @param data
 */
export const mixpanelStartForAvailabilityPageEvent = async (
  eventName: MixpanelEventNamesTypeInterface,
  data: MixpanelPropertyAvailabilityInterface
) => {
  mixpanel.track(eventName, {
    ...data
  });
};

/**
 * Property Report Page - Main Buttons - Rental value
 * @param eventName
 * @param data
 */
export const mixpanelRentalValueReportPageEvent = async (
  eventName: MixpanelEventNamesTypeInterface,
  data: MixpanelRentalValueReportPageInterface
) => {
  mixpanel.track(eventName, {
    ...data
  });
};
/**
 * User details page : invalid phone number
 * @param eventName
 * @param data
 */
export const mixpanelInvalidPhoneNumberEvent = async (
  eventName: MixpanelEventNamesTypeInterface,
  data: MixpanelInvalidPhoneNumberEventInterface
) => {
  mixpanel.track(eventName, {
    ...data
  });
};

/**
 * Property Report Page - Click Links
 * @param eventName
 * @param data
 */
export const mixpanelTextLinkReportPageEvent = async (
  eventName: MixpanelEventNamesTypeInterface,
  data: any
) => {
  mixpanel.track(eventName, {
    ...data
  });
};
