import React, { useState, useEffect } from "react";
import { IonGrid, IonText } from "@ionic/react";
import "./TenantDatabase.scss";
import DataCard from "@components/DataCard/DataCard";
import { TENANT_DATABASE, unknown_text } from "@constants/report.constants";
import { FONT_COLOR, FONT_SIZE, FONT_TYPE, FONT_WEIGHT } from "@constants/enums/typography";

const generateRandom = (min: number, max: number) => {
  const data = Math.floor(Math.random() * (max - min + 1)) + min;
  localStorage.setItem(
    TENANT_DATABASE.tenantsCount,
    JSON.stringify({ value: data, time: Date.now() })
  );
};

const TenantDatabase = () => {
  const [tenantCount, setTenantCount] = useState({
    value: "",
    time: ""
  });
  useEffect(() => {
    if (localStorage.getItem(TENANT_DATABASE.tenantsCount)) {
      const dbLocalStorageData = JSON.parse(
        localStorage.getItem(TENANT_DATABASE.tenantsCount) as string
      );
      console.log(Date.now() - dbLocalStorageData.time);
      if (Date.now() - dbLocalStorageData.time >= TENANT_DATABASE.oneHourInMilliSeconds) {
        localStorage.removeItem(TENANT_DATABASE.tenantsCount);
        generateRandom(TENANT_DATABASE.tenantsMinCount, TENANT_DATABASE.tenantsMaxCount);
      }
    } else {
      generateRandom(TENANT_DATABASE.tenantsMinCount, TENANT_DATABASE.tenantsMaxCount);
    }
    setTenantCount(JSON.parse(localStorage.getItem(TENANT_DATABASE.tenantsCount) as string));
  }, []);

  return (
    <DataCard
      title={{
        titleText: TENANT_DATABASE.title,
        titleFontType: [FONT_SIZE.text_6xl, FONT_WEIGHT.font_medium, FONT_TYPE.capitalize]
      }}
      footer={{
        hasDivider: true,
        footerText: TENANT_DATABASE.footer,
        footerFontType: [FONT_SIZE.text_xs, FONT_WEIGHT.font_medium, FONT_COLOR.grey600_text]
      }}
    >
      <IonGrid className="tenant-number-container">
        <IonText className="tenant-number">
          {Number(tenantCount?.value)?.toLocaleString() || unknown_text}
        </IonText>
        <IonGrid className="tenant-database-subtext-container">
          <IonText className="tenant-database-subtext">{TENANT_DATABASE.subTitle}</IonText>
        </IonGrid>
      </IonGrid>
    </DataCard>
  );
};

export default TenantDatabase;
