import React from "react";
import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import Loader from "@components/Loader";
import { LOADING_TEXT } from "@constants/report.constants";

const LoadingContainer = () => {
  return (
    <IonGrid className="report-loading-container px_16">
      <IonRow className="mb_32">
        <IonCol size="12" className="text_center">
          <IonText className="loading-text">{LOADING_TEXT}</IonText>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <Loader />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default LoadingContainer;
