import React, { ReactNode } from "react";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import apiService from "@utils/apiService";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: string | null;
}

export class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
    error: null
  };

  isApiCalled = false;

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any) {
    // You can also log the error to an error reporting service
    window.addEventListener("popstate", this.resetError);
    this.setState({ error: error.stack });
    if (!this.isApiCalled) {
      this.isApiCalled = true;

      apiService.post("/v1/public/log-error", {
        error: error.stack,
        url: window.location.href
      });
    }
  }

  componentDidUpdate() {
    if (!this.state.hasError) {
      window.removeEventListener("popstate", this.resetError);
    }
  }

  resetError = () => {
    this.isApiCalled = false;
    this.setState({ hasError: false, error: null });
  };

  render() {
    if (this.state.hasError) {
      return <ErrorMessage error={this.state.error} />;
    }
    return this.props.children;
  }
}
