/*
This is a tabular reusable component
which accept data and colCount as props
and generate table rows on basis of data.
data is an array which accept any data type.
*/

import React from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import "./Tabular.scss";
import { arrayToStringClassName } from "@utils/helpers/converter";

interface TabularPropsType {
  colCount: number;
  data: Array<any>;
  column_style?: Array<string>;
  row_style?: Array<string>;
}

const Tabular = ({
  colCount = 0,
  data = [],
  column_style = [],
  row_style = []
}: TabularPropsType) => {
  const getTable = () => {
    const dataJSX = [];
    for (let i = 0; i < data.length; i += colCount) {
      const columns = [];
      for (let j = i; j < i + colCount; j++) {
        columns.push(
          <IonCol className={`table_col ${arrayToStringClassName(column_style)}`}>{data[j]}</IonCol>
        );
      }
      dataJSX.push(
        <IonRow className={`table_row ${arrayToStringClassName(row_style)}`}>{columns}</IonRow>
      );
    }
    return dataJSX;
  };

  return <IonGrid className="tabular">{getTable()}</IonGrid>;
};

export default Tabular;
