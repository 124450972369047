import React, { useEffect } from "react";
import { IonGrid, IonText } from "@ionic/react";
import Button from "@components/Button/Button";
import Mixpanel from "@utils/mixpanel";
import { useAppDispatch, useAppSelector, useWindowDimensions } from "@hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./StepBedrooms.scss";

import {
  BEDROOM_COUNTS,
  BUTTON_LABELS,
  STEPS,
  PROPERTY_VALUATION_FORM_TITLE
} from "@constants/home.constants";
import { resetBedroomCount, setBedroomCount } from "@store/valuation/valuationSlice";
import { MixpanelEventNames } from "@utils/mixpanel/events";
import { JOURNEY_NAME, NEXT_BUTTON_CLICK_TYPE, PAGE_VARIANT } from "@utils/mixpanel/constants";
import { isIOS } from "react-device-detect";
import { populateStateFromQueryStepsData } from "@utils/helpers/populate-state-from-query.helper";
import { addPreviousStep, removeLastStep } from "@utils/helpers/add-query-step.helper";

export interface MixpanelBedroomsInterface {
  "Journey Name": string;
  "Number of Bedrooms": string;
  "Button Pressed": string;
  "Page Variant": string;
}

const StepBedRooms = () => {
  const bedroomCounts = Object.keys(BEDROOM_COUNTS);
  const dispatch = useAppDispatch();
  const selectedBedroomDetails = useAppSelector((state) => state.valuation.bedroomDetails);
  const propertyType = useAppSelector((state) => state.valuation.propertyType);
  const getMixpanelData = (bedrooms: string, button: string): MixpanelBedroomsInterface => {
    return {
      "Journey Name": JOURNEY_NAME.owner,
      "Number of Bedrooms": bedrooms,
      "Button Pressed": button,
      "Page Variant": PAGE_VARIANT.A
    };
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    populateStateFromQueryStepsData(searchParams, dispatch, navigate);
  }, []);
  const { isMobile } = useWindowDimensions();
  const handleBack = () => {
    Mixpanel(
      MixpanelEventNames.pl_valuation_bedroom_number_selection,
      getMixpanelData(selectedBedroomDetails.value.toString(), NEXT_BUTTON_CLICK_TYPE.back)
    );

    dispatch(resetBedroomCount());
    searchParams.set("previousSteps", removeLastStep(STEPS.BEDROOMS, searchParams));
    searchParams.delete(STEPS.BEDROOMS);
    searchParams.set("step", propertyType || STEPS.PROPERTY_TYPE);
    setSearchParams(searchParams);
  };

  const handleNext = (count: string, button: string) => {
    searchParams.set("step", STEPS.CONTACT_DETAILS);
    setSearchParams(searchParams);
    Mixpanel(
      MixpanelEventNames.pl_valuation_bedroom_number_selection,
      getMixpanelData(count, button)
    );
  };
  function setCount(count: string) {
    dispatch(
      setBedroomCount({
        label: count,
        value: BEDROOM_COUNTS[count]
      })
    );
    searchParams.set("previousSteps", addPreviousStep(STEPS.BEDROOMS, searchParams));
    const encryptedBedroomCountData = btoa(count);
    searchParams.set(STEPS.BEDROOMS, encryptedBedroomCountData);
    setSearchParams(searchParams);
    handleNext(BEDROOM_COUNTS[count].toString(), NEXT_BUTTON_CLICK_TYPE.automatic);
  }

  useEffect(() => {
    if (isIOS) {
      document.getElementById("bedroom_form")?.scrollIntoView(true);
    } else if (isMobile) {
      document
        .getElementById("bedroom_form")
        ?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, []);

  return (
    <IonGrid className="bedroom_content" id="bedroom_form">
      <IonGrid className="title-container ">
        <IonText className="title">{PROPERTY_VALUATION_FORM_TITLE.BEDROOM}</IonText>
      </IonGrid>
      <IonGrid className="bedroom_container">
        {bedroomCounts.map((count) => {
          return (
            <Button
              key={count}
              classes={`form_button bedroomCount_button ${
                selectedBedroomDetails?.label === count ? "selectedButton" : ""
              }`}
              label={count}
              onClick={() => setCount(count)}
            />
          );
        })}
      </IonGrid>
      <IonGrid className="navigation_main_container">
        <IonGrid className="navigation_container">
          <Button label={BUTTON_LABELS.BACK} classes="navigation" onClick={handleBack} />
          <Button
            label={BUTTON_LABELS.NEXT}
            classes={`${selectedBedroomDetails?.value ? "navigation" : "navigation_disabled"}`}
            onClick={() => handleNext(selectedBedroomDetails?.label, NEXT_BUTTON_CLICK_TYPE.manual)}
            disabled={selectedBedroomDetails?.value ? false : true}
          />
        </IonGrid>
      </IonGrid>
    </IonGrid>
  );
};

export default StepBedRooms;
