// imports
import React, { useEffect, useState } from "react";
import { IonGrid, IonRow, IonCol, IonInput, IonLabel, IonText, IonImg } from "@ionic/react";

// local imports
import Loader from "../Loader";
import {
  BUTTON_LABELS,
  STEPS,
  STEP_ADDRESS_INPUT_PLACEHOLDER_TEXT
} from "@constants/home.constants";
import apiService from "@utils/apiService/apiService";
import OutsideAlerter from "@hoc/OutsideAlerter/OutsideAlerter";
import { ADDRESS_TITLE } from "@constants/address.constants";
import { useSearchParams } from "react-router-dom";
import { getImagePath } from "@utils/helpers/media.helper";
import { resetAddress, setAddress, validatePostcode } from "@store/valuation/valuationSlice";
import { useDispatch } from "react-redux";
import Button from "@components/Button/Button";
import { useAppSelector } from "@hooks";
import { MixpanelEventNames } from "@utils/mixpanel/events";

// styles
import "./Address.scss";
import { JOURNEY_NAME, NEXT_BUTTON_CLICK_TYPE, PAGE_VARIANT } from "@utils/mixpanel/constants";
import Mixpanel from "@utils/mixpanel";
import { AddressDetails } from "@store/report/types";
import { addPreviousStep } from "@utils/helpers/add-query-step.helper";

// interfaces
interface IAddress {
  id: string;
  type: string;
  summaryline: string;
  locationsummary: string;
  count: number;
}

interface IRetrievedAddress {
  addressline1: string;
  addressline2?: string;
  addressline3?: string;
  addressline4?: string;
  summaryline: string;
  number: string;
  uniquedeliverypointreferencenumber: string;
  premise: string;
  dependentstreet: string;
  street: string;
  posttown: string;
  county: string;
  postcode: string;
  country: string;
  latitude: string;
  longitude: string;
  localauthorityname: string;
}

export const Address = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchText, setSearchText] = useState("");
  const [fetchingAddress, setFetchingAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<IAddress | null>(null);
  const [allAddress, setAllAddresses] = useState<IAddress[]>([]);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [summaryLine, setSummaryLine] = useState<string | null>(null);
  const [retrievedAddress, setRetrievedAddress] = useState<AddressDetails | null>(null);
  const [nestedAddress, setNestedAddress] = useState<IAddress[] | null>([]);

  const previousSelectedAddress = useAppSelector(
    (state) => state.valuation?.address?.formatted_address
  );

  const previousSelectedAddressObject = useAppSelector((state) => state.valuation?.address);

  const dispatch = useDispatch();

  useEffect(() => {
    if (searchText.length === 0 && previousSelectedAddress.length) {
      setSearchText(previousSelectedAddress);
    }
  }, [previousSelectedAddress]);

  useEffect(() => {
    const controller = new AbortController();

    if (searchText && searchText.length > 3) {
      findAddress(searchText, controller.signal);
    } else {
      setSelectedAddress(null);
      setAllAddresses([]);
      setSummaryLine(null);
    }

    if (searchText.length && searchText !== previousSelectedAddress) {
      dispatch(resetAddress());
    }

    return () => controller.abort();
  }, [searchText]);

  useEffect(() => {
    if (retrievedAddress) {
      dispatch(setAddress(retrievedAddress));
    }
  }, [retrievedAddress]);

  useEffect(() => {
    const controller = new AbortController();
    if (selectedAddress && selectedAddress.count === 1) {
      retrieveAddress(selectedAddress.id, controller.signal);
    }

    if (searchText && searchText.length > 3 && selectedAddress && selectedAddress.id) {
      findAddress(searchText, controller.signal, selectedAddress.id);
    }

    return () => controller.abort();
  }, [selectedAddress]);

  const findAddress = async (searchInput: string, abortSignal: AbortSignal, addressId?: string) => {
    if (searchText.length && searchText !== previousSelectedAddress) {
      setFetchingAddress(true);

      const allAddress = await apiService.get(
        `/v1/postcoder/autocomplete/findAll?query=${encodeURIComponent(
          searchInput.replace(/\s\s+/g, " ")
        )}${addressId ? `&pathFilter=${encodeURIComponent(addressId)}` : ""}`,
        { signal: abortSignal }
      );

      if (allAddress && allAddress.data) {
        if (addressId) {
          setNestedAddress(allAddress.data);
        } else {
          setAllAddresses(allAddress.data);
        }
      }
      setFetchingAddress(false);
    }
  };

  const retrieveAddress = async (addressId: string, abortSignal: AbortSignal) => {
    setFetchingAddress(true);
    let address = null;

    if (searchText && searchText !== previousSelectedAddress) {
      address = await apiService.get(
        `/v1/postcoder/autocomplete/retrieve?query=${encodeURIComponent(
          searchText.replace(/\s\s+/g, " ")
        )}&id=${addressId}`,
        { signal: abortSignal }
      );
    }

    if (address && address.data && address.data.length) {
      const serializedAddress = serializeRetrievedAddress(address.data[0] as IRetrievedAddress);
      setRetrievedAddress(serializedAddress);
      setSelectedAddress(null);
      setSearchText(address.data[0]?.formatted_address || "");
      setAllAddresses([]);
      setSummaryLine(null);
      setIsDropDownOpen(false);
      handleNext(NEXT_BUTTON_CLICK_TYPE.automatic, serializedAddress);
    }

    setFetchingAddress(false);
  };

  const serializeRetrievedAddress = (retrievedAddress: IRetrievedAddress): AddressDetails => {
    let addr1 = "";
    let addr2 = "";
    let addr3 = "";
    let addr4 = "";

    if (retrievedAddress.addressline4) {
      addr1 = retrievedAddress.addressline1;
      addr2 = retrievedAddress.addressline2 || "";
      addr3 = retrievedAddress.addressline3 || "";
      addr4 = retrievedAddress.addressline4;
    } else if (retrievedAddress.addressline3) {
      addr1 = retrievedAddress.addressline1;
      addr2 = retrievedAddress.addressline2 || "";
      addr3 = retrievedAddress.addressline3;
    } else if (retrievedAddress.addressline2) {
      addr1 = retrievedAddress.addressline1;
      addr3 = retrievedAddress.addressline2;
    } else {
      addr3 = retrievedAddress.addressline1;
    }

    return {
      address_1: addr1,
      address_2: addr2,
      address_3: addr3,
      address_4: addr4,
      formatted_address: retrievedAddress.summaryline,
      longitude: retrievedAddress.longitude,
      latitude: retrievedAddress.latitude,
      udprn: retrievedAddress.uniquedeliverypointreferencenumber,
      town_city: retrievedAddress.posttown,
      postcode: retrievedAddress.postcode,
      county: retrievedAddress.county,
      street: retrievedAddress.street,
      country: retrievedAddress.country,
      locality: retrievedAddress.localauthorityname
    };
  };

  const handleNext = (
    clickOrigin: string,
    defaultRetrievedAddress?: AddressDetails | undefined
  ) => {
    const address = defaultRetrievedAddress
      ? defaultRetrievedAddress
      : retrievedAddress
      ? retrievedAddress
      : previousSelectedAddressObject;
    const mxEventName = MixpanelEventNames.pl_valuation_postcode_entered;
    const mxPayload = {
      "Journey Name": JOURNEY_NAME.owner,
      "Button Pressed": clickOrigin,
      "Page Variant": PAGE_VARIANT.A,
      Postcode: address?.postcode,
      "Address Selected": address?.formatted_address
    };
    Mixpanel(mxEventName, mxPayload);

    dispatch(validatePostcode(address?.postcode));
    searchParams.set("previousSteps", addPreviousStep(STEPS.ADDRESS, searchParams));
    const encryptedAddress = btoa(JSON.stringify(address));
    searchParams.set("step", STEPS.PROPERTY_TYPE);
    searchParams.set(STEPS.ADDRESS, encryptedAddress);
    setSearchParams(searchParams);
  };

  const renderDropDownContent = () => {
    if (nestedAddress && nestedAddress.length) {
      return (
        <IonGrid>
          {summaryLine && summaryLine.length && (
            <IonRow
              onClick={() => {
                setSummaryLine(null);
                setNestedAddress(null);
                setSelectedAddress(null);
              }}
            >
              <div className="label_wrapper_container">
                <IonRow className="label_wrapper">
                  <div className="label_wrapper-left-section">
                    <IonImg src={getImagePath(`svg/chevron-right.svg`)} />
                  </div>
                  <div className="label_wrapper-right-section">{summaryLine}</div>
                </IonRow>
              </div>
            </IonRow>
          )}
          <IonRow>{nestedAddress.map((nAddress) => renderDropDownSingleItem(nAddress))}</IonRow>
        </IonGrid>
      );
    }

    if (allAddress && allAddress.length) {
      return allAddress.map((address: IAddress) => renderDropDownSingleItem(address));
    }

    return <IonLabel className="capitalize">No Address Result found</IonLabel>;
  };

  const renderDropDownSingleItem = (address: IAddress) => (
    <div
      className="dropdown_list_single_item"
      key={address.id}
      onClick={() => {
        setSelectedAddress(address);
        setSummaryLine(address.summaryline);
      }}
    >
      <span className="address-item">
        {`${address.summaryline}, ${address.locationsummary} ${
          address.count > 1 ? `(${address.count} Addresses)` : ""
        }`}
      </span>
    </div>
  );

  const disableNextButton = () => {
    if (searchText?.length && previousSelectedAddress?.length) {
      return false;
    }

    if (retrievedAddress) {
      return false;
    }

    return true;
  };

  return (
    <IonGrid className="address">
      <IonRow className="title-container">
        <IonCol>
          <IonText className="title-text">{ADDRESS_TITLE}</IonText>
        </IonCol>
      </IonRow>
      <IonGrid className="addressOutsideAlertContainer">
        <OutsideAlerter onOutsideClick={() => setIsDropDownOpen(false)}>
          <IonGrid className="addressContainer">
            <IonRow>
              <IonCol size={fetchingAddress ? "10" : "11"}>
                <IonInput
                  name="address-search"
                  value={searchText}
                  placeholder={STEP_ADDRESS_INPUT_PLACEHOLDER_TEXT}
                  spellCheck={false}
                  className="addressInput"
                  onIonChange={(inputEvent) => {
                    const inputValue = inputEvent.detail?.value ? inputEvent.detail.value : "";
                    setIsDropDownOpen(
                      inputValue !== previousSelectedAddress && inputValue.length !== 0
                        ? true
                        : false
                    );
                    setSearchText(inputValue);
                  }}
                  type="text"
                  onFocus={() =>
                    setIsDropDownOpen(
                      searchText !== previousSelectedAddress && searchText.length !== 0
                        ? true
                        : false
                    )
                  }
                />
              </IonCol>
              {fetchingAddress && (
                <IonCol size="2">
                  <Loader />{" "}
                </IonCol>
              )}
            </IonRow>
            {isDropDownOpen && (
              <IonGrid className="dropdown_list">
                <IonGrid className="dropdown_scroll">{renderDropDownContent()}</IonGrid>
              </IonGrid>
            )}
          </IonGrid>
        </OutsideAlerter>
      </IonGrid>
      <IonRow className="justify-content-center button-container">
        <IonCol size="auto">
          <IonGrid>
            <IonGrid>
              <Button
                label={BUTTON_LABELS.NEXT}
                onClick={() => handleNext(NEXT_BUTTON_CLICK_TYPE.manual)}
                disabled={disableNextButton()}
                classes={previousSelectedAddress ? `navigation` : `navigation_disabled`}
              />
            </IonGrid>
          </IonGrid>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
