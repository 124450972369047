import React from "react";
import { IonGrid, IonImg } from "@ionic/react";
import "./PeakActivity.scss";
import DataCard from "@components/DataCard/DataCard";
import { PEAK_ACTIVITY } from "@constants/report.constants";
import { FONT_COLOR, FONT_SIZE, FONT_TYPE, FONT_WEIGHT } from "@constants/enums/typography";
import { getImagePath } from "@utils/helpers/media.helper";
import { useWindowDimensions } from "@hooks";

const PeakActivity = () => {
  const { isMobile } = useWindowDimensions();
  const imagePath = `svg/peak-activity-graph-${isMobile ? "mobile" : "desktop"}.svg`;

  return (
    <DataCard
      title={{
        titleText: PEAK_ACTIVITY.title,
        titleFontType: [FONT_SIZE.text_6xl, FONT_WEIGHT.font_medium, FONT_TYPE.capitalize]
      }}
      footer={{
        hasDivider: true,
        footerText: PEAK_ACTIVITY.footer,
        footerFontType: [FONT_SIZE.text_xs, FONT_WEIGHT.font_medium, FONT_COLOR.grey600_text]
      }}
    >
      <IonGrid className="peak-activity">
        <IonImg src={getImagePath(imagePath)} />
      </IonGrid>
    </DataCard>
  );
};

export default PeakActivity;
