import { CONTACT_DETAILS_FIELDS } from "@constants/home.contactDetails.constants";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddressDetails } from "@store/report/types";
import apiService from "@utils/apiService";

// interface for store state
export interface ContactDetails {
  [CONTACT_DETAILS_FIELDS.CONTACT_TYPE]: string;
  [CONTACT_DETAILS_FIELDS.FIRST_NAME]: string;
  [CONTACT_DETAILS_FIELDS.SURNAME]: string;
  [CONTACT_DETAILS_FIELDS.EMAIL]: string;
  [CONTACT_DETAILS_FIELDS.PHONE]: string;
  [CONTACT_DETAILS_FIELDS.COUNTRY_CODE]: number;
}

export interface ValuationState {
  address: AddressDetails;
  propertyType: string;
  propertySubType: number;
  contactDetails: ContactDetails;
  bedroomDetails: BedroomDetails;
  propertyAvailabilityDetails: LabelAndValue;
  isInLondon: boolean;
  error?: string;
}

export interface LabelAndValue {
  label: string;
  value: string;
}
export interface BedroomDetails {
  label: string;
  value: number;
}

export interface ReportLinkPayload {
  firstName: string;
  email: string;
  reportUrl: string;
  phone: string;
}

export const validatePostcode = createAsyncThunk(
  "valuation/validate-postcode",
  async (postcode: string | undefined, thunkAPI) => {
    try {
      if (postcode) {
        const validatePostcodeResponse = await apiService.get(
          `/v1/properties/validate-postcode?postcode=${postcode}`
        );
        return validatePostcodeResponse.data.isPostcodeValid;
      } else throw new Error("Postcode is empty!!");
    } catch (err) {
      return thunkAPI.rejectWithValue({ err });
    }
  }
);

export const sendValuationReportLink = createAsyncThunk(
  "valuation/valuation-report-link",
  async (payload: ReportLinkPayload, thunkAPI) => {
    try {
      const reportUrlSendResponse = await apiService.post(
        `/v1/properties/send-valuation-generation-link`,
        { ...payload }
      );

      if (reportUrlSendResponse.status !== 200) throw new Error(reportUrlSendResponse.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue({ err });
    }
  }
);

// initial state
export const initialState: ValuationState = {
  address: {
    address_1: "",
    address_2: "",
    address_3: "",
    address_4: "",
    formatted_address: "",
    longitude: "",
    latitude: "",
    udprn: "",
    town_city: "",
    street: "",
    postcode: "",
    county: "",
    locality: ""
  },
  propertyType: "",
  propertySubType: 0,
  contactDetails: {
    [CONTACT_DETAILS_FIELDS.CONTACT_TYPE]: "",
    [CONTACT_DETAILS_FIELDS.FIRST_NAME]: "",
    [CONTACT_DETAILS_FIELDS.SURNAME]: "",
    [CONTACT_DETAILS_FIELDS.EMAIL]: "",
    [CONTACT_DETAILS_FIELDS.PHONE]: "",
    [CONTACT_DETAILS_FIELDS.COUNTRY_CODE]: 44
  },
  bedroomDetails: {
    label: "",
    value: 0
  },
  propertyAvailabilityDetails: {
    label: "",
    value: ""
  },
  isInLondon: false,
  error: ""
};

export const ValuationSlice = createSlice({
  name: "valuation",
  initialState,
  reducers: {
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    resetAddress: (state) => {
      state.address = initialState.address;
    },
    setPropertyTypeValue: (state, action) => {
      state.propertyType = action.payload;
    },
    resetPropertyTypeValue: (state) => {
      state.propertyType = initialState.propertyType;
    },
    setPropertySubTypeValue: (state, action) => {
      state.propertySubType = action.payload;
    },
    resetPropertySubTypeValue: (state) => {
      state.propertySubType = initialState.propertySubType;
    },
    setContactDetails: (state, action) => {
      state.contactDetails = action.payload;
    },
    resetContactDetails: (state) => {
      state.contactDetails = initialState.contactDetails;
    },
    setBedroomCount: (state, action) => {
      state.bedroomDetails = action.payload;
    },
    resetBedroomCount: (state) => {
      state.bedroomDetails = initialState.bedroomDetails;
    },
    setPropertyAvailability: (state, action) => {
      state.propertyAvailabilityDetails = action.payload;
    },
    resetPropertyAvailability: (state) => {
      state.propertyAvailabilityDetails = initialState.propertyAvailabilityDetails;
    },
    resetState: (state) => {
      state.address = initialState.address;
      state.propertyType = initialState.propertyType;
      state.propertySubType = initialState.propertySubType;
      state.bedroomDetails = initialState.bedroomDetails;
      state.contactDetails = initialState.contactDetails;
      state.propertyAvailabilityDetails = initialState.propertyAvailabilityDetails;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(validatePostcode.fulfilled, (state, action: PayloadAction<any>) => {
      state.isInLondon = action.payload;
      state.error = "";
    });
    builder.addCase(validatePostcode.rejected, (state, action) => {
      state.isInLondon = false;
      state.error = action.error.message;
    });
  }
});

// export actions from slice if any reducers created

export const {
  setAddress,
  resetAddress,
  setPropertyTypeValue,
  resetPropertyTypeValue,
  setContactDetails,
  resetContactDetails,
  setPropertySubTypeValue,
  resetPropertySubTypeValue,
  setBedroomCount,
  resetBedroomCount,
  setPropertyAvailability,
  resetPropertyAvailability,
  resetState
} = ValuationSlice.actions;

export default ValuationSlice.reducer;
