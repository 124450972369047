/**
 * This file will contain all constants related to the environment variables
 */

export const ENVIRONMENT = process.env.NODE_ENV;

export const REACT_APP_PLATFORM = process.env.REACT_APP_PLATFORM
  ? process.env.REACT_APP_PLATFORM
  : "web";

export const REACT_APP_PL_APP_BASE_URL = process.env.REACT_APP_PL_APP_BASE_URL;

export const REACT_APP_LOGGING_ENABLED = process.env.REACT_APP_LOGGING_ENABLED
  ? process.env.REACT_APP_LOGGING_ENABLED
  : "1";

export const REACT_APP_LOGGING_LEVEL = process.env.REACT_APP_LOGGING_LEVEL
  ? process.env.REACT_APP_LOGGING_LEVEL
  : "log";

export const REACT_APP_LIST_PROPERTY_FORM_URL = process.env.REACT_APP_LIST_PROPERTY_FORM_URL;

export const REACT_APP_RENTER_FORM_URL = process.env.REACT_APP_RENTER_FORM_URL;

export const IS_LOCAL_ENV = ["localhost", "127.0.0.1"].includes(window.location.hostname);

export const BASE_API_URL = process.env.REACT_APP_PLAPI_BASE_URL;

const localBuildPath = "/build/assets";

export const BUILD_ASSETS_URL = IS_LOCAL_ENV
  ? localBuildPath
  : `${process.env.REACT_APP_CDN_BASE_URL}${localBuildPath}`;
