export const FOOTER_LIST = {
  TERMS: "Terms",
  PRIVACY_POLICY: "Privacy Policy"
};

export const FOOTER_LINKS = {
  Terms: "https://support.propertyloop.co.uk/hc/en-gb/articles/360015152417-Terms-of-Service",
  "Privacy Policy":
    "https://support.propertyloop.co.uk/hc/en-gb/articles/360015740697-Privacy-Policy"
};

export const FOOTER_COPYRIGHT = `© ${new Date().getFullYear()} Propertyloop. All rights reserved.`;
