export const STEPS = {
  ADDRESS: "Address",
  PROPERTY_TYPE: "PropertyType",
  PROPERTY_SUB_TYPE: "PropertySubType",
  BEDROOMS: "Bedrooms",
  BUNGALOW: "Bungalow",
  FLAT: "Flat",
  HOUSE: "House",
  CONTACT_DETAILS: "ContactDetails",
  PROPERTY_AVAILABILITY: "PropertyAvailability"
};

export const PROPERTY_VALUATION_ROUTES = {
  REPORT: "/report",
  EMAIL_SENT: "/email-sent"
};

export const BUTTON_LABELS = {
  BACK: "Back",
  NEXT: "Next"
} as const;

export const FORM_STEP_FOR_PROGRESS_BAR = {
  [STEPS.PROPERTY_TYPE]: 1,
  [STEPS.FLAT]: 2,
  [STEPS.HOUSE]: 2,
  [STEPS.BUNGALOW]: 2,
  [STEPS.BEDROOMS]: 3,
  [STEPS.CONTACT_DETAILS]: 4,
  [STEPS.PROPERTY_AVAILABILITY]: 5
};

export const TOTAL_STEPS_COUNT = 5;
export const PROPERTY_AVAILABILITY_ENUM: Record<string, string> = {
  now: "Now",
  withinThreeMonths: "Within 3 Months",
  threeToSixMonths: "3-6 Months",
  afterSixMonths: "6+ Months"
};

export const BEDROOM_COUNTS: Record<string, number> = {
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "10+": 10
};

export const PROPERTY_VALUATION_FORM_TITLE = {
  BEDROOM: "How Many bedrooms are there?",
  PROPERTY_AVAILABILITY: "Lastly, When is your property available?"
};

/**
  Start Address Constants
*/
export const STEP_ADDRESS_INPUT_PLACEHOLDER_TEXT =
  "Start typing an address. E.g. Manor Road, Farringdon";
/**
  End Address Constants
*/

/**
  Start Navbar Constants
*/
export const LOCATION_PATHNAME = {
  home: "Home",
  report: "Report"
};
/**
  End Navbar Constants
*/
