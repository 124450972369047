import React from "react";
import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import "./AchievingPercentage.scss";
import DataCard from "@components/DataCard/DataCard";
import {
  ACHIEVING_PERCENTAGE,
  DEFAULT_ACHIEVING_PERCENTAGE,
  unknown_text
} from "@constants/report.constants";
import { FONT_SIZE, FONT_WEIGHT, FONT_TYPE, FONT_COLOR } from "@constants/enums/typography";
import { useAppSelector } from "@hooks";

const AchievingPercentage = () => {
  const { report } = useAppSelector((state) => state.report);
  const { bedroomDetails } = useAppSelector((state) => state.valuation);
  const bedroomCount = parseInt(
    report?.valuationresult?.valuationproperty?.bedrooms || bedroomDetails.value
  );

  const MIN_ACHIEVING_PERCENTAGE =
    report?.neighbourhoodData?.achieve_percentage_min || DEFAULT_ACHIEVING_PERCENTAGE.MIN;
  const MAX_ACHIEVING_PERCENTAGE =
    report?.neighbourhoodData?.achieve_percentage_max || DEFAULT_ACHIEVING_PERCENTAGE.MAX;
  return (
    <DataCard
      title={{
        titleText: ACHIEVING_PERCENTAGE.title,
        titleFontType: [FONT_SIZE.text_6xl, FONT_WEIGHT.font_medium, FONT_TYPE.capitalize]
      }}
      footer={{
        hasDivider: false,
        footerText: `${bedroomCount} ${ACHIEVING_PERCENTAGE.footer}`,
        footerFontType: [FONT_SIZE.text_xl, FONT_WEIGHT.font_normal, FONT_COLOR.grey600_text]
      }}
    >
      <IonGrid className="achieving-percentage">
        <IonRow className="value-container">
          <IonCol>
            <IonText className="percentage-value-text">{`${
              MIN_ACHIEVING_PERCENTAGE || unknown_text
            }-${MAX_ACHIEVING_PERCENTAGE || unknown_text}%`}</IonText>
            <br />
            <IonText className="percentage-sub-text">{ACHIEVING_PERCENTAGE.subTitle}</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </DataCard>
  );
};

export default AchievingPercentage;
