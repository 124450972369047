import React from "react";
import { IonRow } from "@ionic/react";
import "./AddressBanner.scss";
import { ADDRESS_BANNER } from "../../constants/report.constants";
import { useAppSelector } from "@hooks";
import { useLocation, useSearchParams } from "react-router-dom";

const AddressBanner = () => {
  const location = useLocation();

  const address = useAppSelector((state) => state.valuation?.address.formatted_address) || "";

  const firstName = useAppSelector((state) => state.valuation?.contactDetails?.firstName) || "";
  const title = `${
    location.pathname === "/area-not-covered" ? ADDRESS_BANNER.areaNotCovered : ADDRESS_BANNER.title
  }`;

  const [searchParams] = useSearchParams();

  return (
    <IonRow className="banner-container">
      <IonRow className="banner-sub-container">
        <IonRow className="banner-row banner-title capitalize">
          {`Hi ${firstName ? firstName : searchParams.get("first_name") || ""}, ${title}`}
        </IonRow>
        <IonRow className="banner-row banner-subtitle">{address}</IonRow>
      </IonRow>
    </IonRow>
  );
};

export default AddressBanner;
