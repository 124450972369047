import React from "react";
import { IonButton } from "@ionic/react";
import "./Button.scss";

interface ButtonProps {
  label: string | JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  classes?: string;
  styleProps?: Record<string, unknown> | object;
  onBlur?: () => void;
  onFocus?: () => void;
  isLoading?: boolean;
}
export const Button = ({
  label,
  classes,
  disabled,
  styleProps,
  onClick,
  onBlur,
  onFocus
}: ButtonProps) => {
  return (
    <IonButton
      type="button"
      className={`btn ${classes}`}
      disabled={disabled}
      style={{ textTransform: "none", ...styleProps }}
      onClick={onClick}
      onIonBlur={onBlur}
      onIonFocus={onFocus}
    >
      <span>{label}</span>
    </IonButton>
  );
};

export default Button;
