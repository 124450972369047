import React from "react";
import { IonGrid, IonCol, IonText, IonRow } from "@ionic/react";
import Button from "@components/Button/Button";
import "./PropertySits.scss";
import { useAppSelector } from "@hooks";
import {
  DEFAULT_PROPERTY_AVERAGE,
  DEFAULT_PROPERTY_COUNT,
  HBS_PAGE_LINKS,
  PROPERTY_SITS,
  SYMBOLS,
  unknown_text
} from "@constants/report.constants";
import Tabular from "@components/Tabular/Tabular";
import {
  JOURNEY_NAME,
  PAGE_VARIANT,
  REPORT_PAGE_BUTTONS,
  REPORT_PAGE_CARD_TITLES
} from "@utils/mixpanel/constants";
import Mixpanel from "@utils/mixpanel";
import { MixpanelEventNames } from "@utils/mixpanel/events";

interface MixpanelPropertySitsInterface {
  "Journey Name": string;
  "Button Pressed": string;
  "Page Variant": string;
  "Page Section": string;
}

const PropertySits = () => {
  const { report } = useAppSelector((state) => state.report);
  const { bedroomDetails } = useAppSelector((state) => state?.valuation);
  const bedroomCount = parseInt(
    report?.valuationresult?.valuationproperty?.bedrooms || bedroomDetails?.value
  );

  const postcode =
    report?.valuationresult?.valuationproperty?.ap_postcode?.split(" ")[0] || unknown_text;
  const getTotalProperty = () => {
    switch (bedroomCount) {
      case 1:
        return report?.neighbourhoodData?.one_bed_property_count;
      case 2:
        return report?.neighbourhoodData?.two_bed_property_count;
      case 3:
        return report?.neighbourhoodData?.three_bed_property_count;
      case 4:
        return report?.neighbourhoodData?.four_bed_property_count;
      case 5:
        return report?.neighbourhoodData?.five_bed_property_count;
      default:
        return DEFAULT_PROPERTY_COUNT;
    }
  };

  const getTotalAverageValue = () => {
    switch (bedroomCount) {
      case 1:
        return report?.neighbourhoodData?.one_bed_property_avg_value;
      case 2:
        return report?.neighbourhoodData?.two_bed_property_avg_value;
      case 3:
        return report?.neighbourhoodData?.three_bed_property_avg_value;
      case 4:
        return report?.neighbourhoodData?.four_bed_property_avg_value;
      case 5:
        return report?.neighbourhoodData?.five_bed_property_avg_value;
      default:
        return DEFAULT_PROPERTY_AVERAGE;
    }
  };

  const getAskingAveragePrice = () => {
    switch (bedroomCount) {
      case 1:
        return report?.neighbourhoodData?.one_bed_property_avg_asking_price;
      case 2:
        return report?.neighbourhoodData?.two_bed_property_avg_asking_price;
      case 3:
        return report?.neighbourhoodData?.three_bed_property_avg_asking_price;
      case 4:
        return report?.neighbourhoodData?.four_bed_property_avg_asking_price;
      case 5:
        return report?.neighbourhoodData?.five_bed_property_avg_asking_price;
      default:
        return report?.neighbourhoodData?.four_bed_property_avg_asking_price;
    }
  };

  const getSalesAveragePrice = () => {
    switch (bedroomCount) {
      case 1:
        return report?.neighbourhoodData?.one_bed_property_avg_sales_price;
      case 2:
        return report?.neighbourhoodData?.two_bed_property_avg_sales_price;
      case 3:
        return report?.neighbourhoodData?.three_bed_property_avg_sales_price;
      case 4:
        return report?.neighbourhoodData?.four_bed_property_avg_sales_price;
      case 5:
        return report?.neighbourhoodData?.five_bed_property_avg_sales_price;
      default:
        return report?.neighbourhoodData?.five_bed_property_avg_sales_price;
    }
  };

  const getFormattedValue = (value: number | null) =>
    typeof value === "number" ? `${SYMBOLS.POUND}${value.toLocaleString()}` : unknown_text;

  const tableData = [
    {
      title: `${getTotalProperty()?.toLocaleString() || unknown_text}`,
      subTitle: PROPERTY_SITS.total_properties
    },
    {
      title: `${getFormattedValue(getTotalAverageValue())}`,
      subTitle: PROPERTY_SITS.average_value
    },
    {
      title: `${getFormattedValue(getAskingAveragePrice())}`,
      subTitle: PROPERTY_SITS.average_asking_price
    },
    {
      title: `${getFormattedValue(getSalesAveragePrice())}`,
      subTitle: PROPERTY_SITS.average_sales_price
    }
  ];

  const tableJSX = () => {
    return tableData.map((data, index) => (
      <IonCol key={`table-${index}`}>
        <IonText className="summary-text text_xl font_bold">
          <span className={data.title === unknown_text ? `grey600_text` : ""}>{data.title}</span>
        </IonText>
        <br />
        <IonText className="summary-sub-text text_sm font_medium">{data.subTitle}</IonText>
      </IonCol>
    ));
  };

  const handleMixpanel = (button: string) => {
    const mixpanelData: MixpanelPropertySitsInterface = {
      "Journey Name": JOURNEY_NAME.owner,
      "Button Pressed": button,
      "Page Variant": PAGE_VARIANT.A,
      "Page Section": REPORT_PAGE_CARD_TITLES.PROPERTY_SITS_IN_MARKET
    };
    Mixpanel(MixpanelEventNames.property_report_page_main_buttons, mixpanelData);
  };

  return (
    <IonGrid className="property_sits_analysis">
      <IonGrid className="title_container">
        <IonText className="title text_6xl font_medium capitalize">{PROPERTY_SITS.title}</IonText>
        <IonText className="subtitle text_2xl font_medium">
          {bedroomCount} {PROPERTY_SITS.subTitle} {postcode}
        </IonText>
      </IonGrid>

      <IonGrid>
        <Tabular column_style={["table-value"]} data={tableJSX()} colCount={2} />
      </IonGrid>

      <IonGrid className="left_text_wrapper">
        <IonText className="text_xl font_normal grey600_text">
          {bedroomCount} {PROPERTY_SITS.footer}
        </IonText>
      </IonGrid>
      <IonRow className="btn_container">
        <IonCol size="12" sizeMd="5.5">
          <Button
            label="List My Property"
            onClick={() => {
              handleMixpanel(REPORT_PAGE_BUTTONS.LIST_MY_PROPERTY);
              window.open(HBS_PAGE_LINKS.LIST_PROPERTY);
            }}
          />
        </IonCol>
        <IonCol size="12" sizeMd="5.5">
          <Button
            label="Free In-Person Valuation"
            onClick={() => {
              handleMixpanel(REPORT_PAGE_BUTTONS.FREE_IN_PERSON_VALUATION);
              window.open(HBS_PAGE_LINKS.LIST_PROPERTY);
            }}
            classes="btn_outline"
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default PropertySits;
