import React from "react";
import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import {
  MEASUREMENT_SHORT_TEXT,
  PROPERTY_SUMMARY,
  unknown_text
} from "@constants/report.constants";
import { FONT_COLOR, FONT_SIZE, FONT_TYPE, FONT_WEIGHT } from "@constants/enums/typography";
import DataCard from "@components/DataCard/DataCard";
import Tabular from "@components/Tabular/Tabular";
import "./PropertySummary.scss";
import { meterSquareToFeetSquare } from "@utils/helpers/converter";
import { useAppSelector } from "@hooks";
import { useWindowDimensions } from "@hooks";

const PropertySummary = () => {
  const { report } = useAppSelector((state) => state.report);
  const { propertyType } = useAppSelector((state) => state.valuation);
  const { isMobile } = useWindowDimensions();

  const propertyTypeAndSubType =
    (report?.valuationresult?.valuationproperty?.propertytype &&
      report?.valuationresult?.valuationproperty?.propertystyle &&
      report?.valuationresult?.valuationproperty?.propertystyle +
        " " +
        report?.valuationresult?.valuationproperty?.propertytype?.split("/")[0]) ||
    propertyType ||
    unknown_text;

  const getFloorArea = () => {
    const floorArea = report?.valuationresult?.valuationproperty?.floorarea;
    if (floorArea) {
      return (
        Math.round(meterSquareToFeetSquare(parseFloat(floorArea))) +
        " " +
        MEASUREMENT_SHORT_TEXT.SQUARE_FEET
      );
    }
    return unknown_text;
  };
  const tableData = [
    {
      title: `${propertyTypeAndSubType}`,
      subTitle: `Property Type`
    },
    {
      title: getFloorArea(),
      subTitle: "Floor Area"
    },
    {
      title: `${report?.valuationresult?.valuationproperty?.yearbuilt || unknown_text}`,
      subTitle: "Year Built"
    },
    {
      title: `${report?.valuationresult?.valuationproperty?.receptions || unknown_text}`,
      subTitle: "Receptions"
    },
    {
      title: `${report?.valuationresult?.valuationproperty?.bedrooms || unknown_text}`,
      subTitle: "Bedrooms"
    },
    {
      title: `${report?.valuationresult?.valuationproperty?.bathrooms || unknown_text}`,
      subTitle: "Bathrooms"
    }
  ];

  const ColumnCount = isMobile ? 2 : 3;

  const tableJSX = () => {
    return tableData.map((data, index) => (
      <IonCol key={`${index}`}>
        <IonText className="summary-text">
          <span className={data.title === unknown_text ? `grey600_text` : ""}>{data.title}</span>
        </IonText>
        <br />
        <IonText className="summary-sub-text">{data.subTitle}</IonText>
      </IonCol>
    ));
  };

  const borough = report?.valuationresult?.valuationproperty?.borough || "";
  return (
    <DataCard
      title={{
        titleText: PROPERTY_SUMMARY.title,
        titleFontType: [FONT_SIZE.text_6xl, FONT_WEIGHT.font_medium, FONT_TYPE.capitalize]
      }}
      footer={{
        hasDivider: false,
        footerText: PROPERTY_SUMMARY.footer + borough,
        footerFontType: [
          FONT_SIZE.text_xl,
          FONT_WEIGHT.font_semibold,
          FONT_COLOR.black_text_imp,
          borough ? "" : "hide_footer"
        ]
      }}
    >
      <IonGrid className="property-summary">
        <IonRow className="table-container">
          <IonCol>
            <Tabular column_style={["table-value"]} colCount={ColumnCount} data={tableJSX()} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </DataCard>
  );
};

export default PropertySummary;
