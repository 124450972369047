/**
 * Mixpanel Event interface
 */
export type MixpanelEventNamesTypeInterface =
  | "View Valuation Home Page"
  | "PL Valuation - Postcode Entered"
  | "PL Valuation - Building Type Selection"
  | "PL Valuation - Building kind Selection"
  | "PL Valuation - Bedroom Number Selection"
  | "PL Valuation - Enter User Details"
  | "PL Valuation - Availability"
  | "PL Valuation - Top Menu Links"
  | "PL Valuation - Lower Menu Links"
  | "View Property Report Page"
  | "Property Report Page - Click Links"
  | "Property Report Page - Click Start for Free"
  | "Property Report Page - Main Buttons"
  | "Property Report Page - Click Next Pricing"
  | "PL Valuation - Invalid Phone Number";

/**
 * Mixpanel Event object
 */
export const MixpanelEventNames = {
  view_valuation_home_page: "View Valuation Home Page",
  pl_valuation_postcode_entered: "PL Valuation - Postcode Entered",
  pl_valuation_building_type_selection: "PL Valuation - Building Type Selection",
  pl_valuation_building_kind_selection: "PL Valuation - Building kind Selection",
  pl_valuation_bedroom_number_selection: "PL Valuation - Bedroom Number Selection",
  pl_valuation_enter_user_details: "PL Valuation - Enter User Details",
  pl_valuation_availability: "PL Valuation - Availability",
  pl_valuation_top_menu_links: "PL Valuation - Top Menu Links",
  pl_valuation_lower_menu_links: "PL Valuation - Lower Menu Links",
  view_property_report_page: "View Property Report Page",
  property_report_page_click_links: "Property Report Page - Click Links",
  property_report_page_click_start_for_free: "Property Report Page - Click Start for Free",
  property_report_page_main_buttons: "Property Report Page - Main Buttons",
  property_report_page_click_next_pricing: "Property Report Page - Click Next Pricing",
  user_details_invalid_phone_number: "PL Valuation - Invalid Phone Number"
} as const;
