import axios, { AxiosError, AxiosRequestConfig } from "axios";
import toast from "react-hot-toast";

import { BASE_API_URL } from "@constants/env.constants";

class ApiService {
  constructor() {
    axios.interceptors.response.use(
      (res) => {
        return res;
      },
      (err: AxiosError) => {
        if (err.response?.status === 500) toast.error(err.response.data.message);
        else if (err.message) toast.error(err.message);
        return err;
      }
    );
  }

  get(url: string, config: AxiosRequestConfig = {}) {
    return axios.get(BASE_API_URL + url, config);
  }
  delete(url: string, config: AxiosRequestConfig = {}) {
    return axios.delete(BASE_API_URL + url, config);
  }
  post(url: string, payload: Record<string, unknown> | FormData, config: AxiosRequestConfig = {}) {
    return axios.post(BASE_API_URL + url, payload, config);
  }
  put(url: string, payload: Record<string, unknown>, config: AxiosRequestConfig = {}) {
    return axios.put(BASE_API_URL + url, payload, config);
  }
  patch(url: string, payload: Record<string, unknown>, config: AxiosRequestConfig = {}) {
    return axios.patch(BASE_API_URL + url, payload, config);
  }
  setHeader(headerName: string, value: string) {
    axios.defaults.headers.common[headerName] = value;
  }
}

const apiService = new ApiService();
export default apiService;
