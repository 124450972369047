import React, { useState, useRef } from "react";
import { IonImg, IonGrid, IonText } from "@ionic/react";
import { useLocation } from "react-router-dom";

// local imports
import { HEADER_LIST_LABELS, HEADER_LIST_LINKS } from "@constants/header.constant";
import { getImagePath } from "@utils/helpers/media.helper";
import Button from "@components/Button/Button";

// styles
import "./Navbar.scss";
import { JOURNEY_NAME, PAGE_VARIANT } from "@utils/mixpanel/constants";
import { LOCATION_PATHNAME } from "@constants/home.constants";
import Mixpanel from "@utils/mixpanel";
import { MixpanelEventNames } from "@utils/mixpanel/events";
import useOnClickOutside from "@hooks/useOnClickOutside";

// interfaces
interface IHeaderListLink {
  PHONE_NUMBER: string;
  SIGN_IN: string;
  RENTAL_VALUATION: string;
  PL_LOGO: string;
  LIST_PROPERTY: string;
}

const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const mobileNavbarRef = useRef(null);
  useOnClickOutside(mobileNavbarRef, () => {
    setIsExpanded(false);
  });

  const { pathname } = useLocation();

  const handleHeaderTextClick = (headerIdentifier: keyof IHeaderListLink) => {
    const isPhoneNumber = headerIdentifier === HEADER_LIST_LABELS.PHONE_NUMBER.IDENTIFIER;

    sendMixpanelData(headerIdentifier, HEADER_LIST_LINKS[headerIdentifier]);

    HEADER_LIST_LINKS[headerIdentifier] &&
      !isPhoneNumber &&
      window.open(HEADER_LIST_LINKS[headerIdentifier]);
  };

  const sendMixpanelData = (headerIdentifier: keyof IHeaderListLink, targetUrl: string) => {
    const mxEventName = MixpanelEventNames.pl_valuation_top_menu_links;
    const mxPayload = {
      "Page Variant": PAGE_VARIANT.A,
      "Journey Name": JOURNEY_NAME.owner,
      "Page Name":
        LOCATION_PATHNAME[pathname.replace(/[^\w\s]/gi, "") as keyof typeof LOCATION_PATHNAME],
      "Link Name": headerIdentifier,
      "Target URL": targetUrl
    };
    Mixpanel(mxEventName, mxPayload);
  };

  return (
    <>
      <IonGrid className={`${isExpanded ? "black_curtain" : ""}`} />
      <IonGrid className={`navbar`}>
        <IonGrid className="workContainer">
          <IonImg
            src={getImagePath(`svg/propertyloop-logo.svg`)}
            className="pl_logo"
            onClick={() =>
              handleHeaderTextClick(HEADER_LIST_LABELS.PL_LOGO.LABEL as keyof IHeaderListLink)
            }
          />
          <IonGrid className="nav_items_container">
            <IonGrid>
              <IonText
                onClick={() =>
                  handleHeaderTextClick(
                    HEADER_LIST_LABELS.PHONE_NUMBER.IDENTIFIER as keyof IHeaderListLink
                  )
                }
              >
                <a className="phone-number" href={`tel:${HEADER_LIST_LINKS.PHONE_NUMBER}`}>
                  {HEADER_LIST_LABELS.PHONE_NUMBER.LABEL}
                </a>
              </IonText>
            </IonGrid>
            <IonGrid onClick={() => window.open(`${HEADER_LIST_LINKS.LIST_PROPERTY}`)}>
              <IonText>{HEADER_LIST_LABELS.LIST_PROPERTY.LABEL}</IonText>
            </IonGrid>
            <IonGrid>
              <IonText
                onClick={() =>
                  handleHeaderTextClick(
                    HEADER_LIST_LABELS.RENTAL_VALUATION.IDENTIFIER as keyof IHeaderListLink
                  )
                }
              >
                {HEADER_LIST_LABELS.RENTAL_VALUATION.LABEL}
              </IonText>
            </IonGrid>
            {/* Uncomment below line when page are available */}
            {/* <IonGrid>
            <IonText>Fees</IonText>
          </IonGrid>
          <IonGrid>
            <IonText>About Us</IonText>
          </IonGrid> */}
            <IonGrid>
              <Button
                onClick={() => {
                  handleHeaderTextClick(
                    HEADER_LIST_LABELS.SIGN_IN.IDENTIFIER as keyof IHeaderListLink
                  );
                  window.open(`${HEADER_LIST_LINKS.SIGN_IN}`);
                }}
                label={HEADER_LIST_LABELS.SIGN_IN.LABEL}
                classes="sign_in_btn"
              />
            </IonGrid>
            <IonGrid className="hamburger_menu" onClick={() => setIsExpanded(!isExpanded)}>
              <IonImg src={getImagePath(`svg/hamburger_icon.svg`)} />
            </IonGrid>
          </IonGrid>
        </IonGrid>
      </IonGrid>
      {isExpanded && (
        <IonGrid ref={mobileNavbarRef} className="navbar_expanded">
          <IonGrid className="close_btn" onClick={() => setIsExpanded(!isExpanded)}>
            <IonImg src={getImagePath(`svg/close.svg`)} />
          </IonGrid>
          <IonGrid className="expanded_menu_list">
            <IonGrid>
              <IonText
                onClick={() => {
                  setIsExpanded(false);
                  handleHeaderTextClick(
                    HEADER_LIST_LABELS.PHONE_NUMBER.IDENTIFIER as keyof IHeaderListLink
                  );
                }}
              >
                <a className="phone-number" href={`${HEADER_LIST_LINKS.PHONE_NUMBER}`}>
                  {HEADER_LIST_LABELS.PHONE_NUMBER.LABEL}
                </a>
              </IonText>
            </IonGrid>
            <IonGrid onClick={() => window.open(`${HEADER_LIST_LINKS.LIST_PROPERTY}`)}>
              <IonText>{HEADER_LIST_LABELS.LIST_PROPERTY.LABEL}</IonText>
            </IonGrid>
            <IonGrid>
              <IonText
                onClick={() => {
                  setIsExpanded(false);
                  handleHeaderTextClick(
                    HEADER_LIST_LABELS.RENTAL_VALUATION.IDENTIFIER as keyof IHeaderListLink
                  );
                }}
              >
                {HEADER_LIST_LABELS.RENTAL_VALUATION.LABEL}
              </IonText>
            </IonGrid>
            {/*  uncomment this when pages available for below link  */}
            {/* <IonGrid>
              <IonText>Fees</IonText>
            </IonGrid>
            <IonGrid>
              <IonText>About Us</IonText>
            </IonGrid> */}
          </IonGrid>
          <div className="seperator" />
          <IonGrid>
            <Button
              onClick={() => {
                setIsExpanded(false);
                handleHeaderTextClick(
                  HEADER_LIST_LABELS.SIGN_IN.IDENTIFIER as keyof IHeaderListLink
                );
                window.open(`${HEADER_LIST_LINKS.SIGN_IN}`);
              }}
              label={HEADER_LIST_LABELS.SIGN_IN.LABEL}
              classes="sign_in_btn"
            />
          </IonGrid>
        </IonGrid>
      )}
    </>
  );
};

export default Navbar;
