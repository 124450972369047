import React from "react";
import { FONT_SIZE, FONT_WEIGHT } from "@constants/enums/typography";
import { IonGrid, IonImg, IonText } from "@ionic/react";
import { getImagePath } from "@utils/helpers/media.helper";
import "./IconGridBox.scss";

interface IconListSingleObjInterface {
  show: boolean;
  title: string;
  path: string;
  valuation_key?: string;
}

interface IconGridBoxInterface {
  iconList: IconListSingleObjInterface[];
}

const IconGridBox = ({ iconList }: IconGridBoxInterface) => {
  return (
    <IonGrid className="grid_icon_wrapper">
      {iconList &&
        iconList?.length &&
        iconList.map(
          (singleIcon: any) =>
            singleIcon.show && (
              <IonGrid className="grid_icon_col" key={singleIcon.title}>
                <IonImg className="grid_icon" src={getImagePath(`${singleIcon.path}`)} />
                <IonText
                  className={`${FONT_SIZE.text_xl} ${FONT_WEIGHT.font_normal} grid_icon_text`}
                >
                  {singleIcon.title}
                </IonText>
              </IonGrid>
            )
        )}
    </IonGrid>
  );
};

export default IconGridBox;
