import React from "react";
import { IonGrid } from "@ionic/react";
import Loader from "@components/Loader";

interface Props {
  Component: any;
  fallback?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null;
}

const LazyLoadComponent = ({ Component, fallback = <Loader /> }: Props) => {
  return (
    <React.Suspense fallback={fallback}>
      <IonGrid className="p_0 ">
        <Component />
      </IonGrid>
    </React.Suspense>
  );
};

export default LazyLoadComponent;
