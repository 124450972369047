import React, { useEffect } from "react";
import "./App.scss";
import getMissingEnvVars from "@utils/envVars/EnvVars";
import { setLogRocketHeader, setupLogRocket, setLogRocketUser } from "@utils/logRocket/LogRocket";
import setupLogger from "@utils/logger/Logger";
import WebRoutes from "./WebRoutes";
import { useAppSelector } from "@hooks";
import { initialState } from "@store/valuation/valuationSlice";

function App() {
  const contactDetails =
    useAppSelector((state) => state.valuation.contactDetails) || initialState.contactDetails;
  const user = {
    email: contactDetails.email,
    phoneNumber: contactDetails.phone,
    uid: contactDetails.email
  };

  /* this useEffect runs on component mount for setup related tasks */

  useEffect(() => {
    (async () => {
      setupLogger();
      setupLogRocket();
      await setLogRocketHeader();
      const missingEnvVars = getMissingEnvVars();
      if (missingEnvVars.length > 0) {
        alert(`ERROR: Missing environment variable(s):\n${missingEnvVars.join("\n")}`);
      }
    })();
  }, []);

  useEffect(() => {
    setLogRocketUser(user);
  }, [user]);

  return (
    <>
      <WebRoutes />
    </>
  );
}

export default App;
