import React from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { IonGrid } from "@ionic/react";
import LazyLoadComponent from "@hoc/LazyLoadComponent";

import { ReactQueryDevtools } from "react-query/devtools";
import { IS_LOCAL_ENV } from "@constants/env.constants";
import Home from "@containers/Home/Home";
import ValuationReportContainer from "@containers/ValuationReportContainer/ValuationReportContainer";
import Navbar from "@components/Navbar/Navbar";
import Footer from "@components/Footer/Footer";
import { useWindowDimensions } from "@hooks";
import { STEPS } from "@constants/home.constants";
import { AreaNotCovered } from "@containers/AreaNotCovered/AreaNotCovered";
import EmailSent from "@containers/EmailSent/EmailSent";

const WebRoutes = () => {
  const { isMobile } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  const step: string | null = searchParams.get("step");
  return (
    <>
      <Navbar />
      <Routes>
        <Route
          path="/home"
          element={
            <IonGrid className="page_body">
              <IonGrid className="page_wrapper">
                <LazyLoadComponent Component={Home} />
                {!(isMobile && step != STEPS.ADDRESS) && (
                  <IonGrid className="footer_wrapper">
                    <IonGrid className="workContainer">
                      <Footer />
                    </IonGrid>
                  </IonGrid>
                )}
              </IonGrid>
            </IonGrid>
          }
        />
        <Route
          path="/report"
          element={
            <>
              <LazyLoadComponent Component={ValuationReportContainer} />
            </>
          }
        />
        <Route
          path="/area-not-covered"
          element={
            <>
              <LazyLoadComponent Component={AreaNotCovered} />
            </>
          }
        />
        <Route
          path="/email-sent"
          element={
            <IonGrid className="emailSent">
              <LazyLoadComponent Component={EmailSent} />
              <IonGrid className="footer_wrapper">
                <IonGrid className="workContainer">
                  <Footer />
                </IonGrid>
              </IonGrid>
            </IonGrid>
          }
        />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>

      {IS_LOCAL_ENV && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
    </>
  );
};

export default WebRoutes;
