// imports
import React from "react";

// local imports
import { Address } from "@components/Address";

const StepAddress = () => {
  return <Address />;
};

export default StepAddress;
