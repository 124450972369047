export const ACHIEVING_PERCENTAGE = {
  title: "Properties near you are achieving",
  footer:
    "bedroom properties in this area, that have been professionally marketed and correctly priced, have remained buoyant throughout the past year.",
  subTitle: "Above asking price"
} as const;
export const PROPERTY_SUMMARY = {
  title: "Your property summary",
  footer: "Your property is in the borough of "
} as const;
export const PROPERTY_SITS = {
  title: "where your property sits in the market",
  subTitle: "Bedroom Homes in",
  footer:
    "bedroom properties in this area, that have been professionally marketed and correctly priced, have remained buoyant throughout the past year.",
  total_properties: "Total properties",
  average_value: "Average value",
  average_asking_price: "Average asking price",
  average_sales_price: "Average sales price"
} as const;

export const DEFAULT_ACHIEVING_PERCENTAGE = {
  MIN: 7,
  MAX: 14
} as const;

export const PEAK_ACTIVITY = {
  title: "Peak activity in your area",
  footer:
    "This is the average peak activity for your area over the last 10 years for completed lets."
} as const;

export const ICON_LIST = [
  {
    title: "Corporates",
    path: "svg/corporate.svg",
    valuation_key: "corporate_tenants"
  },
  {
    title: "Professionals",
    path: "svg/professionals.svg",
    valuation_key: "professionals"
  },
  {
    title: "Executive Couples",
    path: "svg/couples.svg",
    valuation_key: "executive_couples"
  },
  {
    title: "Families",
    path: "svg/families.svg",
    valuation_key: "families"
  },
  {
    title: "Students",
    path: "svg/student.svg",
    valuation_key: "students"
  },
  {
    title: "Diplomats",
    path: "svg/diplomats.svg",
    valuation_key: "diplomats"
  }
];
export const TENANT_DATABASE = {
  title: "Our Tenant Database",
  footer:
    "This is an estimation of the current renters we have on our database who are looking to rent in your area.",
  subTitle: "Renters with propertyLoop for this area",
  tenantsCount: "tenantsCount",
  oneHourInMilliSeconds: 60 * 60 * 1000,
  tenantsMinCount: 3489,
  tenantsMaxCount: 4172
} as const;

export const TENANT_PROFILE_FOR_AREA = {
  title: "Tenant profile for your area",
  footer:
    "Based on the assessment of your property and the current market trends, we believe that your property will have access to the tenant types above.",
  subTitle: "Renters with propertyLoop for this area"
} as const;

export const RENTAL_VALUE = {
  title: "Your Estimated Rental Value",
  subFooter: " Call 020 8989 8989 to speak with an expert",
  buttonTextOne: "List My Property",
  buttonTextTwo: "Free in-person Valuation",
  footer:
    "This is an AI generated valuation and may not be accurate. Please call us or book a free in-person valuation for an exact figure."
} as const;

export const RENTAL_RANGE = {
  title: "Your Estimated Rental Range",
  subFooter: "Book a Free In-Person Valuation to Get an Exact Figure",
  footer:
    "This is an estimation of the current renters we have on our database who are looking to rent in your area."
} as const;

export const ADDRESS_BANNER = {
  title: "Here's Your Property Report",
  areaNotCovered: "Sorry, we’re not covering your area just yet."
} as const;

export const LIST_PROPERTY_BANNER = {
  title: "FIND TENANTS + NO LET, NO FEE",
  subTitle: "List Your Property",
  areaNotCoveredSubTitle: "Register For Free",
  footerTitle: "Learn More",
  footerSubTitle:
    "Let our local lettings experts help you find great tenants today. Save up to 50% in fees",
  areaNotCoveredfooterSubTitle:
    "Store all your documents in our document Vault, and receive notifications when any are due to expire.",
  whatsapp: "https://wa.me/447782644480"
} as const;

export const unknown_text = "unknown";

export enum LET_SECTION_TYPE_ENUM {
  CURRENTLY = "Currently for let",
  RECENTLY = "Recently let"
}

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

export const MARKET_ANALYSIS = {
  title: `Your Neighbourhood & Market Analysis`,
  content: `  Overall, we have consistently found that the market for 3 bedroom flats in the area,
  that have been professionally marketed and correctly priced, has remained buoyant
  throughout the past year.`,
  firstSubContent: ` Overall, we have consistently found that the market for`,
  secondSubContent: `bedroom properties in the area,
  that have been professionally marketed and correctly priced, has remained buoyant
  throughout the past year.`
} as const;

export const TESTIMONIAL_DATA = [
  {
    author: "Mark",
    text: "My amazing lettings expert rented my property in Canary Wharf. She had extensive knowledge of the area, and was available at any time of the day. I was able to save money on fees and receive a personable, one to one service.",
    rating: 5
  },
  {
    author: "David",
    text: "I dealt with my last agent for years but got increasingly annoyed with the lack of support when their office was shut. My Lettings expert was always available, no matter the time.",
    rating: 5
  },
  {
    author: "Sarah",
    text: "My property taxes have eaten into my profits. I just couldn’t afford the fees other agents were charging me anymore. My lettings expert and not only are the fees less than half, but the service is incredible.",
    rating: 5
  },
  {
    author: "Jeremy",
    text: "I like to stick to who I know and hate changing relationships. I was hesitant about using PropertyLoop, until I met my  lettings expert. All I can say is I should have changed years ago.",
    rating: 5
  },
  {
    author: "Peter",
    text: "I have used a well-known high street agent for over 10 years. I have dealt with well over 60 different individuals, good and bad. With PropertyLoop I had one point of contact and really felt that my expert went the extra mile with their service.",
    rating: 5
  },
  {
    author: "Emma",
    text: "My Lettings expert  was 5 star rated and I can see why! They had quality tenants ready and waiting for my property and was able to get multiple offers within 2 days.Review 6. I was fed up of not being able to reach my previous agent on weekends and evenings when I needed them most. With PropertyLoop my lettings expert was available outside of normal working hours and was able to respond immediately.",
    rating: 5
  },
  {
    author: "Louise",
    text: "I was fed up of not being able to reach my previous agent on weekends and evenings when I needed them most. With PropertyLoop my lettings expert was available outside of normal working hours and was able to respond immediately.",
    rating: 5
  },
  {
    author: "Richard",
    text: "I have recently rented out my apartment with Property Loop. The process was easy, with the expert discussing a listing strategy with me first. 24 hours later my advert was listed and with in 12 hours I had an offer I was more than happy.",
    rating: 5
  },
  {
    author: "Michael",
    text: "Being a Landlord is becoming more and more expensive . Where other agents have increased their fees, PropertyLoop’s fees are fair and transparent, with no nasty hidden extras. Everything I needed was included within one fee.",
    rating: 5
  }
] as const;

export const DEFAULT_PROPERTY_COUNT = 3241;
export const DEFAULT_PROPERTY_AVERAGE = 341;
export const IN_PERSON_VALUATION_CTA =
  "https://meetings.hubspot.com/matt-mould/in-person-vauation-booking-valuation-page";

export const DIPLOMATS_KEY = "diplomats";
export const AGENT_PHONE_NUMBER = "020 8530 9045";
export const TESTIMONIALS = "Testimonials";

export const SYMBOLS = {
  POUND: "£"
} as const;

export const MEASUREMENT_SHORT_TEXT = {
  SQUARE_FEET: "Sqft"
};

export const LOADING_TEXT = "Please wait while we fetch your property valuation";

export const HBS_PAGE_LINKS = {
  GET_STARTED: "https://www.propertyloop.co.uk/get-started",
  LIST_PROPERTY: "https://www.propertyloop.co.uk/list-property"
};
