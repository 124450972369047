import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import StepAddress from "@components/Home/StepAddress";
import StepContactDetails from "@components/Home/StepContactDetails/StepContactDetails";
import StepBedrooms from "@components/Home/StepBedrooms/StepBedrooms";
import StepPropertyAvailability from "@components/Home/StepPropertyAvailability/StepPropertyAvailability";
import { FORM_STEP_FOR_PROGRESS_BAR, STEPS, TOTAL_STEPS_COUNT } from "@constants/home.constants";

import BackgroundHoc from "@hoc/BackgroundHoc/BackgroundHoc";
import PropertyTypeOption from "@components/Home/PropertyType/PropertyTypeOption";
import { IonGrid, IonProgressBar, IonText } from "@ionic/react";
import "./Home.scss";

// Mixpanel imports
import Mixpanel from "@utils/mixpanel";
import { MixpanelEventNames } from "@utils/mixpanel/events";

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const step: string | null = searchParams.get("step");
  const propertyTypeSection = [STEPS.PROPERTY_TYPE, STEPS.FLAT, STEPS.HOUSE, STEPS.BUNGALOW];
  useEffect(() => {
    if (!step || !Object.values(STEPS).includes(step)) {
      searchParams.set("step", STEPS.ADDRESS);
      setSearchParams(searchParams);
    }

    // triggering mixpanel event
    Mixpanel(MixpanelEventNames.view_valuation_home_page);
  }, []);

  const renderStep = () => {
    if (step === STEPS.ADDRESS) return <StepAddress />;
    if (step && propertyTypeSection.includes(step)) return <PropertyTypeOption />;
    if (step === STEPS.BEDROOMS) return <StepBedrooms />;
    if (step === STEPS.CONTACT_DETAILS) return <StepContactDetails />;
    if (step === STEPS.PROPERTY_AVAILABILITY) return <StepPropertyAvailability />;
    return <StepAddress />;
  };

  const progressVisibleSteps = [
    ...propertyTypeSection,
    STEPS.BEDROOMS,
    STEPS.CONTACT_DETAILS,
    STEPS.PROPERTY_AVAILABILITY
  ];

  return (
    <BackgroundHoc>
      <IonGrid className="contentContainer form_container">
        {step && progressVisibleSteps.includes(step) && (
          <IonGrid className="progress_bar_container">
            <IonText className="progress_bar_title">PROGRESS</IonText>
            <IonProgressBar
              value={FORM_STEP_FOR_PROGRESS_BAR[step || STEPS.PROPERTY_TYPE] / TOTAL_STEPS_COUNT}
              className="progress_bar"
            />
          </IonGrid>
        )}
        {renderStep()}
      </IonGrid>
    </BackgroundHoc>
  );
};

export default Home;
